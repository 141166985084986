import React from "react"
import PropTypes from "prop-types"
import {Card, List} from "antd"
import {makeNewsAvatar} from "../util/Utility";

const NewsList = ({news, isApureBase, toggleEditor, toggleNewsModal}) => {
    
    const showEditor = () => {
        toggleEditor();
    };
    
    const showModal = (item) => toggleNewsModal(item);
    
    return <Card title="News and Announcements" type="inner"
                 extra={isApureBase ? <a onClick={showEditor}>Add News</a> : null}>
        <List
            itemLayout='horizontal'
            dataSource={news}
            renderItem={item => <List.Item
                actions={[<a onClick={() => showModal(item)}>more</a>]}>
                <List.Item.Meta
                    avatar={makeNewsAvatar(item.type)}
                    title={item.title}
                    description={item.preview}
                />
            </List.Item>}
        />
    </Card>
};

NewsList.propTypes = {
    news: PropTypes.array.isRequired,
    isApureBase: PropTypes.bool.isRequired,
    toggleEditor: PropTypes.func.isRequired,
    toggleNewsModal: PropTypes.func.isRequired
};

export default NewsList