import React from "react"
import PropTypes from "prop-types"
import {Col, Form, Input, List, Modal, Radio, Row} from "antd";
import ReactMde from "react-mde"
import 'react-mde/lib/styles/css/react-mde-all.css';
import {formItemLayout, makeNewsAvatar} from "../util/Utility";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

const FormItem = Form.Item;
const RadioGroup = Radio.Group;

const NewsEditor = ({
    form: {validateFields, getFieldDecorator, getFieldValue},
    save,
    update,
    visible,
    toggleEditor,
    converter,
    isEdit,
    newsItem,
    saving,
}) => {

    const saveNews = (e) => {
        e.preventDefault();
        validateFields(async (err, values) => {
            if (!err) {
                const {title, preview, type, content: {markdown}} = values;
                const news = {id: newsItem.id, title, preview, content: markdown, type};
                if (isEdit) await update(news);
                else await save(news);
                toggleEditor();
            }
        });
    };


    const type = getFieldValue('type');
    const title = getFieldValue('title');
    const preview = getFieldValue('preview');
    const previewNews = {type, title, preview};
    return (
        <Modal
            visible={visible}
            title="News Editor"
            okText="Publish News"
            onOk={saveNews}
            onCancel={toggleEditor}
            width={1000}
            confirmLoading={saving}
        >
            <Form>
                <Row>
                    <Col span={12}>
                        <FormItem label="Title" {...formItemLayout}>
                            {
                                getFieldDecorator('title', {
                                    initialValue: isEdit ? newsItem.title : null,
                                    rules: [{required: true, message: "Please specify a title"}],
                                })(<Input/>)
                            }
                        </FormItem>
                        <FormItem label="Preview" {...formItemLayout}>
                            {
                                getFieldDecorator('preview', {
                                    initialValue: isEdit ? newsItem.preview : null,
                                    rules: [{required: true, message: "Please specify a preview"}],
                                })(<Input/>)
                            }
                        </FormItem>
                        <FormItem label="Type" {...formItemLayout}>
                            {
                                getFieldDecorator('type', {
                                    rules: [{required: true, message: "Please specify a preview"}],
                                    initialValue: isEdit ? newsItem.type : 'info',
                                })(<RadioGroup>
                                    <Radio value="info">Info</Radio>
                                    <Radio value="warning">Warning</Radio>
                                </RadioGroup>)
                            }
                        </FormItem>
                    </Col>
                    <Col span={12}>
                        <h3>Preview</h3>
                        <List
                            itemLayout='horizontal'
                            dataSource={[previewNews]}
                            renderItem={item => <List.Item
                                actions={[<a onClick={() => null}>more</a>]}>
                                <List.Item.Meta
                                    avatar={makeNewsAvatar(item.type)}
                                    title={item.title}
                                    description={item.preview}
                                />
                            </List.Item>}
                        />
                    </Col>
                </Row>
                <FormItem>
                    {
                        getFieldDecorator('content', {
                            initialValue: isEdit ? {markdown: newsItem.content, html: ''} : {markdown: '', html: ''},
                            rules: [{required: true, message: "Please add some content"}],
                            valuePropName: 'editorState'
                        })(<ReactMde
                            buttonContentOptions={{iconProvider: name => <FontAwesomeIcon icon={name}/>,}}
                            layout='horizontal'
                            generateMarkdownPreview={(markdown) => Promise.resolve(converter.makeHtml(markdown))}
                        />)
                    }
                </FormItem>
            </Form>
        </Modal>
    );
};

NewsEditor.propTypes = {
    visible: PropTypes.bool.isRequired,
    toggleEditor: PropTypes.func.isRequired,
    save: PropTypes.func.isRequired,
    update: PropTypes.func.isRequired,
    converter: PropTypes.object.isRequired,
    isEdit: PropTypes.bool.isRequired,
    newsItem: PropTypes.object.isRequired,
    saving: PropTypes.bool.isRequired
};

export default Form.create()(NewsEditor)
