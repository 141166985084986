import React, {useState} from "react";
import {Tag, Tree} from "antd";
import LovTypes from "../../constants/LovTypes";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import * as WizardActions from "../../reducers/wizard";
import * as SelectionActions from "../../reducers/selection";
import {bindActionCreators} from "redux";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

const TreeNode = Tree.TreeNode;

const SelectionTree = ({
    removeLovs,
    selectType,
    selectedType: selectedTypeProp,
    selection,
    availableLovs,
}) => {
    const [selectedNode, setSelectedNode] = useState(null);
    const [selectedType, setSelectedType] = useState(null);
    const [selectedIsLeaf, setSelectedIsLeaf] = useState(null);

    const onClose = (lovType, lov) => removeLovs(lovType, [lov]);

    const onSelect = (keys, e) => {
        const isLeaf = e.node.props.isLeaf;
        const type = isLeaf ? e.node.props.lovType : keys[0];
        setSelectedNode(keys[0]);
        setSelectedType(type);
        setSelectedIsLeaf(isLeaf);
        if (type) selectType(type);
    };

    const makeType = (type, lovs) => {
        const lovNodes = lovs.length > 0 ? lovs.map(l => (
            <TreeNode
                lovType={type.type}
                isLeaf key={l.type + l.code}
                title={<Tag closable onClose={() => onClose(type.type, l)}>{l.label}</Tag>}
            />
        )) : <TreeNode disabled title="Not Selected" lovType={type.type} isLeaf/>;

        return (
            <TreeNode
                key={type.type}
                title={<span><FontAwesomeIcon icon={type.icon}/> <b>{type.title}</b></span>}
            >
                {lovNodes}
            </TreeNode>
        )
    };


    const types = LovTypes
        .filter(l => availableLovs.some(a => a.type === l.type))
        .map(t => makeType(t, selection[t.type] || []));

    // noinspection EqualityComparisonWithCoercionJS
    const selectedKey = !selectedIsLeaf && selectedType != selectedTypeProp ? selectedTypeProp : selectedNode;

    return (
        <Tree
            showLine
            defaultExpandAll={true}
            autoExpandParent={true}
            selectedKeys={[selectedKey]}
            onSelect={onSelect}
        >
            {types}
        </Tree>
    );
};

SelectionTree.propTypes = {
    removeLovs: PropTypes.func.isRequired,
    selection: PropTypes.object.isRequired,
    selectedType: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
    selection: state.selection,
    selectedType: state.wizard.selectedType,
    availableLovs: state.auth.availableLovs
});

const mapDispatchToProps = dispatch => ({
    removeLovs: bindActionCreators(SelectionActions, dispatch).removeLovs,
    selectType: bindActionCreators(WizardActions, dispatch).selectType,
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectionTree)
