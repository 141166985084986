/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useRef} from "react";
import {compose} from 'recompose';
import PropTypes from "prop-types";
import {includes, find, sortBy} from 'lodash';
import {Form, Input, Select, Modal} from "antd";
import "../css/flags.css";


const CountryModal = ({
    form,
    initializeSelection,
    countries,
    cancel,
    showModal,
}) => {
    const titleInputRef = useRef(null);
    useEffect(() => {focusAfterTimeout()}, []);
    console.log('CountryModal');

    const handleOk = e => {
        e.preventDefault();
        form.validateFields((err, {country, name}) => {
            if (!err) {
                initializeSelection(find(countries, ['key', country]), name);
            }
        });
    };

    const handleCancel = e => {
        e.preventDefault();
        cancel()
    };

    const focusAfterTimeout = () => setTimeout(() => {
        showModal && titleInputRef.focus()
    });

    const {getFieldDecorator} = form;
    return (
          <Modal
            wrapClassName="vertical-center-modal"
            title="Create Selection"
            onOk={handleOk}
            onCancel={handleCancel}
            width={600}
            visible={showModal}
            closable={true}
            maskCloseable={true}
            okText="Create Selection"
        >
            <Form onSubmit={handleOk}>
                <Form.Item label="Name your list">
                    {
                        getFieldDecorator('name', {
                            rules: [
                                {required: true, message: "Please fill in a name"},
                                {max: 200, message: "Maximum allowed characters in selection name is 200"},
                            ]
                        })(
                            <Input
                                ref={titleInputRef}
                                style={{maxWidth: 300}}
                                placeholder="Input a name for the selection"
                                autoFocus
                            />
                        )
                    }
                </Form.Item>
                <Form.Item label="Choose your country">
                    {
                        getFieldDecorator('country', {
                            rules: [{required: true, message: "Please select a country"}],
                        })(
                            <Select
                                placeholder="Select country"
                                showSearch
                                optionFilterProp={"key"}
                                style={{maxWidth: 300}}
                                filterOption={(input, option) =>
                                  includes(option.key.toLowerCase(), input.toLowerCase()) || includes(option.props.value.toLowerCase(), input.toLowerCase())
                                }
                            >
                                {sortBy(countries, ['name']).map(c => (
                                    <Select.Option key={c.name} value={c.key}>
                                        <span>{c.getFlag()} {c.name}</span>
                                    </Select.Option>
                                ))}
                            </Select>
                        )
                    }
                </Form.Item>
            </Form>
        </Modal>
    );
};

CountryModal.propTypes = {
    cancel: PropTypes.func.isRequired,
    countries: PropTypes.array.isRequired,
    showModal: PropTypes.bool.isRequired,
};

export default compose(
    Form.create(),
)(CountryModal);
