import * as React from "react";
import PropTypes from "prop-types"
import {Input} from "antd"
import * as WizardActions from "../../reducers/wizard";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

const Search = Input.Search;

const SearchBar = ({search, setSearch}) =>
    <Search className="search-bar" size="large" value={search} placeholder="Search"
            onChange={e => setSearch(e.target.value)}/>;

SearchBar.propTypes = {
    search: PropTypes.string.isRequired,
    setSearch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    search: state.wizard.search,
});

const mapDispatchToProps = dispatch => ({
    setSearch: bindActionCreators(WizardActions, dispatch).setSearch,
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar)