import * as React from "react";
import PropTypes from "prop-types"
import {Button, Card, Col, Form, Input, Row} from "antd"
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {NavLink} from "react-router-dom";
import * as SettingsActions from "../reducers/settings"
import {WithFooter} from "./WithFooter";

const FormItem = Form.Item;

class ResetPassword extends React.Component {

    onSubmit = (e) => {
        e.preventDefault();
        const {form, resetPassword} = this.props;
        form.validateFields(async (err, values) => {
            if (!err) {
                await resetPassword(values.email)
            }
        })
    };

    render() {
        const {form, resetting} = this.props;
        const {getFieldDecorator} = form;
        return <WithFooter>
            <Row>
                <Col span={6} offset={9}>
                    <div className="login-header">
                        <Card title="Can't log in?">
                            <Form onSubmit={this.onSubmit} type="vertical">
                                <FormItem label="We'll send a new password to" style={{textAlign: 'left'}}>
                                    {
                                        getFieldDecorator('email', {
                                            rules: [
                                                {
                                                    required: true,
                                                    whitespace: true,
                                                    type: "email",
                                                    message: 'Please enter a valid email address'
                                                },
                                            ]
                                        })(<Input placeholder="Enter email"/>)
                                    }
                                </FormItem>
                                <FormItem>
                                    <Button loading={resetting} type="primary" htmlType="submit">Reset password</Button>
                                </FormItem>
                            </Form>
                        </Card>
                    </div>
                    <br/>

                    <p style={{textAlign: 'center'}}><NavLink to="/login">Return to log in</NavLink></p>
                </Col>
            </Row>
        </WithFooter>
            ;
    }
}

ResetPassword.propTypes = {
    resetPassword: PropTypes.func.isRequired,
    resetting: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
    resetting: state.settings.resetting
});

const mapDispatchToProps = dispatch => ({
    resetPassword: bindActionCreators(SettingsActions, dispatch).resetPassword
});

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(ResetPassword));
