import axios from 'axios'
import {error, success} from "../constants/Messaging";
import {evalError, getConfig} from "./utility";

const initialState = {
    news: [],
    users: [],
    fetching: false,
    saving: false
};

export const DASHBOARD_FETCH = "DASHBOARD_FETCH";
const NEWS_SAVE = "NEWS_SAVE";
const NEWS_DELETE = "NEWS_DELETE";
const NEWS_UPDATE = "NEWS_UPDATE";

export default (state = initialState, action) => {
    switch (action.type) {
        case DASHBOARD_FETCH:
            switch (action.status) {
                case 0:
                    return {...state, fetching: true};
                case 1:
                    return {
                        ...state, fetching: false,
                        news: action.news.map(n => {
                            n.date = new Date(n.date);
                            return n
                        }),
                        users: action.users
                    };
                default:
                    error(action.error);
                    return {...state, fetching: false}
            }
        case NEWS_SAVE:
            switch (action.status) {
                case 0:
                    return {...state, saving: true};
                case 1:
                    success('The News entry was saved');
                    return {...state, saving: false};
                default:
                    error(action.error);
                    return {...state, saving: false};
            }
        case NEWS_DELETE:
            switch (action.status) {
                case 0:
                    return {...state, saving: true};
                case 1:
                    success('The News entry was deleted');
                    return {...state, saving: false};
                default:
                    error(action.error);
                    return {...state, saving: false};
            }
        case NEWS_UPDATE:
            switch (action.status) {
                case 0:
                    return {...state, saving: true};
                case 1:
                    success('The News entry was updated');
                    return {...state, saving: false};
                default:
                    error(action.error);
                    return {...state, saving: false};
            }
        default:
            return state;
    }
}

const fetchDataReq = () => ({type: DASHBOARD_FETCH, status: 0});
const fetchDataSuc = (users, archive, news) => ({type: DASHBOARD_FETCH, status: 1, users, archive, news});
const fetchDataErr = error => ({type: DASHBOARD_FETCH, status: -1, error});
export const fetchData = () => (dispatch, getState) => {
    dispatch(fetchDataReq());
    axios.get('/api/dashboard', getConfig(getState))
        .then(res => dispatch(fetchDataSuc(res.data.users, res.data.archive, res.data.news)))
        .catch(err => evalError(dispatch, fetchDataErr, err))
};

const saveNewsReq = () => ({type: NEWS_SAVE, status: 0});
const saveNewsSuc = () => ({type: NEWS_SAVE, status: 1});
const saveNewsErr = error => ({type: NEWS_SAVE, status: -1, error});
export const saveNews = (news) => (dispatch, getState) => {
    dispatch(saveNewsReq());
    axios.post('/api/dashboard/news', news, getConfig(getState))
        .then(res => dispatch(saveNewsSuc()))
        .catch(err => evalError(dispatch, saveNewsErr, err))
};

const deleteNewsReq = (id) => ({type: NEWS_DELETE, status: 0, id});
const deleteNewsSuc = (id) => ({type: NEWS_DELETE, status: 1, id});
const deleteNewsErr = error => ({type: NEWS_DELETE, status: -1, error});
export const deleteNews = (id) => (dispatch, getState) => {
    dispatch(deleteNewsReq(id));
    axios.delete(`/api/dashboard/news/${id}`, getConfig(getState))
        .then(res => dispatch(deleteNewsSuc(id)))
        .catch(err => evalError(dispatch, deleteNewsErr, err))
};

const updateNewsReq = (news) => ({type: NEWS_UPDATE, status: 0, news});
const updateNewsSuc = (news) => ({type: NEWS_UPDATE, status: 1, news});
const updateNewsErr = error => ({type: NEWS_UPDATE, status: -1, error});
export const updateNews = (news) => (dispatch, getState) => {
    dispatch(updateNewsReq(news));
    axios.put('/api/dashboard/news', news, getConfig(getState))
        .then(res => dispatch(updateNewsSuc(res.data)))
        .catch(err => evalError(dispatch, updateNewsErr, err))
};