import axios from "axios";
import {error, success} from "../constants/Messaging";
import {notification} from "antd"
import {evalError, getConfig, mapFetchedUsers} from "./utility";

const initialState = {
    loading: false,
    accounts: [],
    ownAccount: {},
    creating: false,
    resetting: false,
    userEditing: false,
    fetching: false,
    fetchingIps: false,
    users: [],
    ips: [],
    logins: [],
    ldapAccounts: [],
    isFetchingLdap: false,
};

const PASSWORD_CHANGE = "PASSWORD_CHANGE";
const PASSWORD_RESET = "PASSWORD_RESET";
const USER_REGISTER = "USER_REGISTER";
export const ACCOUNTS_GET = "ACCOUNTS_GET";
const ACCOUNT_CREATE = "ACCOUNT_CREATE";
const IPS_FETCH = "IPS_FETCH";
const LOGINS_FETCH = "LOGINS_FETCH";
const LDAP_FETCH = "LDAP_FETCH";

export default function (state = initialState, action) {
    switch (action.type) {
        case PASSWORD_CHANGE:
            switch (action.status) {
                case 0:
                    return {...state, loading: true};
                case 1:
                    success("Your password has been updated");
                    return {...state, loading: false};
                default:
                    error(`There was a problem changing your password: ${action.error.message || action.error}`);
                    return {...state, loading: false};
            }
        case USER_REGISTER:
            switch (action.status) {
                case 0:
                    return {...state, userEditing: true};
                case 1:
                    const {email, role} = action.user;
                    if (action.edit) success(`User ${email} has been updated!`);
                    else success(`User ${email} (${role}) has been registered!`);
                    return {...state, userEditing: false};
                default:
                    error(action.error.message || action.error);
                    return {...state, userEditing: false};
            }
        case ACCOUNTS_GET:
            switch (action.status) {
                case 0:
                    return {...state, fetching: true};
                case 1:
                    const {accounts} = action;
                    const users = [].concat(...accounts.map(acc => acc.users.map(mapFetchedUsers)));
                    return {...state, accounts, fetching: false, users};
                default:
                    error(action.error);
                    return {...state, fetching: false};
            }
        case ACCOUNT_CREATE:
            switch (action.status) {
                case 0:
                    return {...state, creating: true};
                case 1:
                    const {name} = action.accountId;
                    if (action.edit) success(`Successfully updated Account ${name}`);
                    else success(`Successfully created Account ${name}`);
                    return {...state, creating: false};
                default:
                    error(action.error);
                    return {...state, creating: false};
            }
        case IPS_FETCH:
            switch (action.status) {
                case 0:
                    return {...state, fetchingIps: true};
                case 1:
                    const {ips} = action;
                    return {...state, fetchingIps: false, ips};
                default:
                    return {...state, fetchingIps: false};
            }
        case LOGINS_FETCH:
            switch (action.status) {
                case 0:
                    return {...state};
                case 1:
                    const {logins} = action;
                    return {...state, logins};
                default:
                    return {...state}
            }
        case PASSWORD_RESET:
            switch (action.status) {
                case 0:
                    return {...state, resetting: true};
                case 1:
                    notification.success({
                        message: 'Your password has been reset!',
                        description: 'Check your mail to find your new password.',
                        duration: 5
                    });
                    return {...state, resetting: false};
                default:
                    return {...state, resetting: false};
            }
        case LDAP_FETCH:
            switch (action.status) {
                case 0:
                    return {...state, isFetchingLdap: true};
                case 1:
                    return {...state, ldapAccounts: action.list, isFetchingLdap: false};
                default:
                    return {...state}
            }
        default:
            return state;
    }
}

/**
 * Ldap Fetch
 */
const fetchLdapAccountsReq = () => ({type: LDAP_FETCH, status: 0});
const fetchLdapAccountsRec = (list) => ({type: LDAP_FETCH, list, status: 1});
const fetchLdapAccountsErr = error => ({type: LDAP_FETCH, status: -1, error});
export const fetchLdapAccounts = () => (dispatch, getState) => {
    dispatch(fetchLdapAccountsReq());
    return axios.get(`/api/user/ldap`, getConfig(getState))
        .then(res => dispatch(fetchLdapAccountsRec(res.data)))
        .catch(err => dispatch(fetchLdapAccountsErr(err)))
};

/**
 * Change Password
 */
const changePasswordReq = () => ({type: PASSWORD_CHANGE, status: 0});
const changePasswordRec = () => ({type: PASSWORD_CHANGE, status: 1});
const changePasswordErr = error => ({type: PASSWORD_CHANGE, status: -1, error});
export const changePassword = (change) => (dispatch, getState) => {
    dispatch(changePasswordReq());
    if (change.newpass != change.confirm) return dispatch(changePasswordErr({
        type: "PASSWORD_MISMATCH",
        message: "The passwords do not match!"
    }));
    return axios.put(`/api/user/password`, change, getConfig(getState))
        .then(res => dispatch(changePasswordRec())) // TODO update token ?
        .catch(err => evalError(dispatch, changePasswordErr, err))
};

/**
 * Reset Password
 */
const resetPasswordReq = () => ({type: PASSWORD_RESET, status: 0});
const resetPasswordRec = () => ({type: PASSWORD_RESET, status: 1});
const resetPasswordErr = error => ({type: PASSWORD_RESET, status: -1, error});
export const resetPassword = (email) => (dispatch, getState) => {
    dispatch(resetPasswordReq());
    const config = email
        ? {method: 'put', url: '/auth/reset', data: email}
        : {method: 'put', url: '/api/user/password/reset', ...getConfig(getState)};
    return axios(config)
        .then(res => dispatch(resetPasswordRec()))
        .catch(err => evalError(dispatch, resetPasswordErr, err))
};

/**
 * Register User
 * @param user
 */
const registerUserReq = user => ({type: USER_REGISTER, status: 0, user});
const registerUserRec = (user, edit) => ({type: USER_REGISTER, status: 1, user, edit});
const registerUserErr = error => ({type: USER_REGISTER, status: -1, error});
export const registerUser = (user, edit) => (dispatch, getState) => {
    dispatch(registerUserReq(user));
    return axios({
        method: edit ? 'put' : 'post',
        url: '/api/user',
        data: user,
        ...getConfig(getState)
    }).then(res => {
        dispatch(registerUserRec(user, edit));
        dispatch(getAccounts())
    }).catch(err => evalError(dispatch, registerUserErr, err))
};

/**
 * Get all Accounts
 */
const getAccountReq = () => ({type: ACCOUNTS_GET, status: 0});
const getAccountRec = accounts => ({type: ACCOUNTS_GET, status: 1, accounts});
const getAccountErr = error => ({type: ACCOUNTS_GET, status: -1, error});
export const getAccounts = () => (dispatch, getState) => {
    dispatch(getAccountReq());
    return axios.get('/api/account', getConfig(getState))
        .then(res => dispatch(getAccountRec(res.data)))
        .catch(err => evalError(dispatch, getAccountErr, err))
};

/**
 * Create Account
 */
const createAccountReq = account => ({type: ACCOUNT_CREATE, status: 0, accountId: account});
const createAccountRec = (account, edit) => ({type: ACCOUNT_CREATE, status: 1, accountId: account, edit});
const createAccountErr = error => ({type: ACCOUNT_CREATE, status: -1, error});
export const createAccount = (account, edit) => (dispatch, getState) => {
    dispatch(createAccountReq(account));
    return axios({
        method: edit ? 'put' : 'post',
        url: '/api/account',
        data: account,
        ...getConfig(getState)
    }).then(res => {
        dispatch(createAccountRec(account, edit));
        dispatch(getAccountRec(res.data))
    }).catch(err => evalError(dispatch, createAccountErr, err))
};

/**
 * Fetch IPs
 */
const fetchIpsReq = () => ({type: IPS_FETCH, status: 0});
const fetchIpsRec = ips => ({type: IPS_FETCH, status: 1, ips});
const fetchIpsErr = error => ({type: IPS_FETCH, status: -1, error});
export const fetchIps = () => (dispatch, getState) => {
    dispatch(fetchIpsReq());
    return axios.get('/api/access', getConfig(getState))
        .then(res => dispatch(fetchIpsRec(res.data)))
        .catch(err => evalError(dispatch, fetchIpsErr, err))
};

/**
 * Fetch Logins
 */
const fetchLoginsReq = () => ({type: LOGINS_FETCH, status: 0});
const fetchLoginsRec = logins => ({type: LOGINS_FETCH, status: 1, logins});
const fetchLoginsErr = error => ({type: LOGINS_FETCH, status: -1, error});
export const fetchLogins = () => (dispatch, getState) => {
    dispatch(fetchLoginsReq());
    return axios.get('/api/access/login', getConfig(getState))
        .then(res => dispatch(fetchLoginsRec(res.data)))
        .catch(err => evalError(dispatch, fetchLoginsErr, err))
};
