import axios from "axios"
import {endsWith,toLower} from 'lodash';
import {error, info, success} from "../constants/Messaging";
import {evalError, getConfig} from "./utility"
import * as auth from "../authentication/authentication";
import Countries from "../constants/Countries"
import {byType} from "../constants/LovTypes"
import {ACCOUNTS_GET} from "./settings"
import {COUNTRY_SET} from "./selection";
import {isArray} from "lodash"

const initialState = {
    loggedIn: false,
    id: null,
    userName: '',
    email: '',
    currentlySending: false,
    errorMessage: '',
    token: null,
    role: null,
    countries: [],
    lovs: [],
    bricks: {},
    availableLovs: [],
    accountId: null,
    ownAccount: {},
    exp: null,
    isApureBase: false,
    hasApureBase: false,
    activating: false,
    verifying: false,
    showLocationVerifier: false
};

const CHANGE_FORM = 'CHANGE_FORM';
const SET_AUTH = 'SET_AUTH';
const SENDING_REQUEST = 'SENDING_REQUEST';
const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE';
const SET_CLAIMS = "SET_CLAIMS";
const SET_TOKEN = "SET_TOKEN";
const ACTIVATE_USER = "ACTIVATE_USER";
const VERIFY_LOCATION = "VERIFY_LOCATION";

export default (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_FORM:
            return {...state, email: action.email};
        case SET_AUTH:
            return {...state, loggedIn: action.newState};
        case SENDING_REQUEST:
            return {...state, currentlySending: action.sending};
        case SET_ERROR_MESSAGE:
            const {message, type} = action.error;
            const showLocationVerifier = type === "IpUnknown";
            if (!showLocationVerifier) {
                error(message);
                return {...state, errorMessage: message};
            }
            else return {...state, errorMessage: message, showLocationVerifier};
        case SET_CLAIMS:
            const {claims} = action;
            const {id, email, userName, exp, role, accountId, countries, lovs, bricks, downloadViaBox} = claims || {};
            const isApureBase = accountId === 1;
            const hasApureBase = endsWith(toLower(email), '@apurebase.com');
            const finalExp = new Date(exp * 1000); // somehow have to x 1000 to get the correct amount
            const availableCountries = countries ? countries.map(c => Countries[c]) : [];
            const normalLovs = (lovs ? lovs.map(byType) : []);
            return {
                ...state,
                id,
                email,
                role,
                accountId,
                exp: finalExp,
                countries: availableCountries,
                lovs: normalLovs,
                bricks: formatBricks(bricks),
                isApureBase,
                hasApureBase,
                userName,
                downloadViaBox
            };
        case SET_TOKEN:
            const {token, rememberMe} = action;
            if (rememberMe) {
                localStorage.mailingtoken = token;
            }
            return {...state, token};
        case ACCOUNTS_GET:
            if (action.status === 1) {
                const ownAccount = action.accounts.find(acc => acc.id === state.accountId);
                return {...state, ownAccount};
            }
            else return state;
        case ACTIVATE_USER:
            switch (action.status) {
                case 0:
                    return {...state, activating: true};
                case 1:
                    success('The User has been activated!');
                    return {...state, activating: false};
                default:
                    error(action.error);
                    return {...state, activating: false};
            }
        case VERIFY_LOCATION:
            switch (action.status) {
                case 0:
                    return {...state, verifying: true};
                case 1:
                    return {...state, verifying: false, showLocationVerifier: false};
                default:
                    error(action.error.message);
                    return {...state, verifying: false};
            }
        case COUNTRY_SET:
            const {country} = action;
            const availableLovs = getAvailableLovs(country, state.lovs, state.bricks);
            return {...state, availableLovs};
        
        default:
            return state
    }
}

/**
 * Get the correct LovType for the user's bricks.
 * Incoming bricks look like: [DK-BRICK, FI-FARMMO]
 * The isArray check is for backwards compatibility regarding the auth change. See MT-148
 * @param bricks
 */
const formatBricks = (bricks) => bricks && isArray(bricks) ? bricks.reduce((acc, code) => {
    const split = code.split("|");
    acc[split[0]] = byType(split[1]);
    return acc
}, {}) : {};

export const init = () => (dispatch, getState) => {
    const token = localStorage.mailingtoken;
    if (token && token.indexOf(".") > 0) {
        const claims = auth.decodeToken(token);
        dispatch(setClaims(claims));
        dispatch(setToken(token));
        dispatch(setAuthState(true));
        const hasValidToken = checkAuth(dispatch, getState);
        if (hasValidToken) {
            refreshToken(dispatch, getState)
        }
    }
};

/**
 * Logs an userId in
 * @param  {string} email The name of the userId to be logged in
 * @param  {string} password The password of the userId to be logged in
 * @param  {bool}   rememberMe Whether the userId should be remembered in localStorage
 */
export const login = (email, password, rememberMe) => dispatch => {
    // Show the loading indicator, hide the last error
    dispatch(sendingRequest(true));
    
    return auth.login(email, password, (result, err) => {
        // When the request is finished, hide the loading indicator
        if (!err) {
            const {token, claims} = result;
            localStorage.mailingtoken = token;
            localStorage.mailingemail = email;
            dispatch(setClaims(claims));
            dispatch(setToken(token, rememberMe));
            dispatch(changeForm(email));
        } else evalError(dispatch, setErrorMessage, err);
        dispatch(sendingRequest(false));
        dispatch(setAuthState(err === null));
    });
};

/**
 * Logs the current userId out
 */
export const logout = () => dispatch => {
    delete localStorage.mailingtoken;
    delete localStorage.mailingemail;
    dispatch(changeForm(''));
    dispatch(setAuthState(false));
    dispatch(setClaims(null, null));
    dispatch({type: "RESET"});
};

/**
 * Activate User
 */
const activateUserReq = hash => ({type: ACTIVATE_USER, status: 0, hash});
const activateUserRec = () => ({type: ACTIVATE_USER, status: 1});
const activateUserErr = error => ({type: ACTIVATE_USER, status: -1, error});
export const activateUser = hash => (dispatch, getState) => {
    dispatch(activateUserReq(hash));
    return axios.post(`/auth/activate/${hash}`)
        .then(res => dispatch(activateUserRec()))
        .catch(err => evalError(dispatch, activateUserErr, err))
};

/**
 * Sets the authentication state of the application
 * @param {boolean} newState True means a userId is logged in, false means no userId is logged in
 */
export const setAuthState = newState => ({type: SET_AUTH, newState});


/**
 * Sets the form state
 * @param  {string} email The new text of the name input field of the form
 * @return {object}                   Formatted action for the reducer to handle
 */
export const changeForm = email => ({type: CHANGE_FORM, email});


/**
 * Sets the requestSending state, which displays a loading indicator during requests
 * @param  {boolean} sending The new state the app should have
 * @return {object}          Formatted action for the reducer to handle
 */
export const sendingRequest = sending => ({type: SENDING_REQUEST, sending});

export const setClaims = claims => ({type: SET_CLAIMS, claims});

export const setToken = (token, rememberMe) => ({type: SET_TOKEN, token, rememberMe});

/**
 * Sets the errorMessage state, which displays the ErrorMessage component when it is not empty
 */
const setErrorMessage = error => ({type: SET_ERROR_MESSAGE, error});

export const checkAuth = (dispatch, getState) => {
    const exp = getState().auth.exp;
    if (exp && new Date() > exp) {
        info("Your access token has expired, please log in again");
        dispatch(logout());
        return false
    }
    return true
};

const getAvailableLovs = (country, lovs, bricks) => {
    const countryBricks = ((country ? [bricks[country.key.toUpperCase()]] : []) || []).filter(lov => lovs.includes(lov)); // TODO simplify and allow multiple bricks
    const userLovs = lovs.filter(lov => !lov.isBrick());
    return [...userLovs, ...countryBricks];
};

/**
 * Regularly refresh the token to prevent preemptive logout
 * @param dispatch
 * @param getState
 */
const refreshToken = (dispatch, getState) => {
    axios.get('/auth/refresh', getConfig(getState))
        .then(res => extractToken(res.data, dispatch))
        .catch(err => console.error(err))
};

const verifyLocationReq = code => ({type: VERIFY_LOCATION, status: 0, code});
const verifyLocationSuc = () => ({type: VERIFY_LOCATION, status: 1});
const verifyLocationErr = error => ({type: VERIFY_LOCATION, status: -1, error});
export const verifyLocation = (code, email) => (dispatch, getState) => {
    dispatch(verifyLocationReq(code));
    return axios.post(`/auth/verify`, {email, code})
        .then(res => {
            extractToken(res.data, dispatch);
            dispatch(verifyLocationSuc());
        })
        .catch(err => dispatch(verifyLocationErr(err)))
};

const extractToken = (data, dispatch) => {
    const {token, claims} = auth.extractTokenAndClaims(data);
    dispatch(setToken(token, true));
    dispatch(setClaims(claims));
    dispatch(setAuthState(true));
};