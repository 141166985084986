import Sorts from "../constants/Sorts";

export const determineCountInSelection = (total, inSelection) => !inSelection && inSelection !== 0 ? total : inSelection;

/**
 * Combines current lovs of action.lovType with lovs of action.lovs
 * @param state
 * @param action
 * @return {{}}
 */
export const handleLovAdd = (state, action) => {
    const {lovType, lovs} = action;
    const newState = {...state};
    newState[lovType] = [...state[lovType], ...lovs].sort(Sorts.sortAlphabet.fun);
    return newState;
};

/**
 * Removes current lovs of action.lovType if they are in action.lovs
 * @param state
 * @param action
 * @return {{}}
 */
export const handleLovDel = (state, action) => {
    const {lovType, lovs} = action;
    const newState = {...state};
    const lovsByKey = lovs.map(l => l.code);
    newState[lovType] = [...state[lovType].filter(r => lovsByKey.indexOf(r.code) === -1)].sort(Sorts.sortAlphabet.fun);
    return newState
};

