import React from "react"
import {Button, Col, Modal, Popconfirm, Row} from "antd";
import PropTypes from "prop-types"
import {makeNewsAvatar} from "../util/Utility";

export const NewsModal = ({visible, item, toggle, converter, isAdminAndAPureBase, deleteNews, editNewsItem}) =>
    <Modal visible={visible} maskClosable={true} width={800} onCancel={() => toggle()} onOk={() => toggle()}
           footer={[
               <Button key='close' onClick={() => toggle()}>Close</Button>,
               isAdminAndAPureBase
                   ? <Button key='edit' type='dashed' icon='edit' onClick={() => editNewsItem()}>Edit</Button>
                   : null,
               isAdminAndAPureBase
                   ? <Popconfirm key='delete' title="Are you sure you want to delete this News entry?"
                                 onConfirm={e => deleteNews(item.id)}>
                       <Button type='danger' icon='delete'>Delete</Button>
                   </Popconfirm>
                   : null,
           ]}
           title={
               <Row gutter={30} style={{fontSize: 20}}>
                   <Col span={1}>{makeNewsAvatar(item.type)}</Col>
                   <Col span={23} style={{paddingTop: 5}}>{item.title}</Col>
               </Row>
           }>
        
        <div dangerouslySetInnerHTML={{__html: converter.makeHtml(item.content)}}/>
        <span className="floatRight">{item.date.toLocaleString()}</span>
    
    
    </Modal>;

NewsModal.propTypes = {
    item: PropTypes.object.isRequired,
    visible: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    converter: PropTypes.object.isRequired,
    isAdminAndAPureBase: PropTypes.bool.isRequired,
    deleteNews: PropTypes.func.isRequired,
    editNewsItem: PropTypes.func.isRequired
};