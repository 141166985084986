import axios from "axios";

/**
 * Logs a userId in
 * @param  {string}   email The name of the userId
 * @param  {string}   password The password of the userId
 * @param  {Function} callback Called after a userId was logged in on the remote server
 */
export const login = (email, password, callback) => {
    // If there is a token in the localStorage, the userId already is authenticated
    if (loggedIn()) {
        callback(true);
        return;
    }
    return axios.post('auth/login', {email: email, password})
        .then((response) => {
            // If the userId was authenticated successfully, save a random token to the localStorage
            if (response.data.token) {
                const tokenClaims = extractTokenAndClaims(response.data);
                callback(tokenClaims, null);
                return Promise.resolve();
            } else {
                // If there was a problem authenticating the userId, show an error on the form
                callback(false, response.error);
                return Promise.reject(response.error);
            }
        }).catch(error => callback(false, error));
};

/**
 * Checks if anybody is logged in
 * @return {boolean} True if there is a logged in userId, false if there isn't
 */
export const loggedIn = () => {
    return !!localStorage.mailingtoken && localStorage.mailingtoken !== "undefined";
};

export const extractTokenAndClaims = data => {
    const token = data.token;
    const claims = decodeToken(token);
    return {token, claims};
};

export const decodeToken = (token) => {
    const firstIndex = token.indexOf(".");
    const lastIndex = token.lastIndexOf(".");
    const toDecode = token.substring(firstIndex + 1, lastIndex);
    const decoded = window.atob(toDecode);
    return JSON.parse(decoded)
};