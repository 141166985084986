import React from 'react';
import {Form, Input} from 'antd';
import {formItemLayout} from "../UserEditor";

export default ({
                    initialValue,
                    form: {getFieldDecorator},
                    disabled = false
                }) => (
    <Form.Item label="Last Name" {...formItemLayout}>
        {getFieldDecorator('lastname', {
            rules: [
                {required: true, whitespace: true}
            ],
            initialValue,
        })(<Input disabled={disabled}/>)}
    </Form.Item>
);
