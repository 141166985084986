import * as React from "react";
import PropTypes from "prop-types"
import {Button, Input, Select} from "antd"
import Bricks from "../../constants/Bricks"

const SelectOption = Select.Option;
const InputGroup = Input.Group;

const BrickAdd = ({countryOptions, addBrick, changeBrickCountry, changeBrickType, disabled, brickCountry, brickType}) => {
    
    return <div>
        
        <InputGroup compact>
            <Select style={{width: '30%'}} placeholder="Select a Country" value={brickCountry}
                    onChange={changeBrickCountry}>
                {countryOptions}
            </Select>
            <Select style={{width: '30%'}} placeholder="Select a Brick type" value={brickType}
                    onChange={changeBrickType}>
                {Bricks.map(brick => <SelectOption key={brick.key}>{brick.name}</SelectOption>)}
            </Select>
            
            <Button type="primary" icon="plus" onClick={addBrick} disabled={disabled}/>
        </InputGroup>
    
    </div>
    
};

BrickAdd.propTypes = {
    countryOptions: PropTypes.array.isRequired,
    addBrick: PropTypes.func.isRequired,
    changeBrickCountry: PropTypes.func.isRequired,
    changeBrickType: PropTypes.func.isRequired,
    formItemLayout: PropTypes.object.isRequired,
    disabled: PropTypes.bool.isRequired,
    getFieldDecorator: PropTypes.func.isRequired,
    brickCountry: PropTypes.string,
    brickType: PropTypes.string
};

export default BrickAdd