import * as React from "react";
import PropTypes from "prop-types"

class Overview extends React.Component {
    
    render() {
        const {email} = this.props;
        return <div style={{padding: 24}}>
            <h1>Overview</h1>
            <p>Email: {email}</p>
        </div>
    }
    
}

Overview.propTypes = {
    email: PropTypes.string.isRequired
};

export default Overview