import React from "react"
import PropTypes from "prop-types"
import {Button, Col, Popconfirm, Row, Table} from "antd"
import {Country} from "../constants/Countries"
import {onlyUnique} from "../util/Utility";
import {text} from "../constants/Sorts"

const ArchiveTable = ({loading, deleting, onSelectChange, dataSource, onChange, deleteSelection, sortedInfo, selectedRowKeys, history}) => {
    const rowSelection = {type: 'radio', onChange: onSelectChange, selectedRowKeys};
    
    const columns = [
        {
            title: "Countries",
            key: "country",
            dataIndex: "country",
            sorter: (a, b) => text(a.country, b.country),
            sortOrder: sortedInfo.columnKey === 'country' && sortedInfo.order,
            render: (text, record, index) => Country.getFlag(text)
        },
        {title: "Title", dataIndex: "title"},
        {
            title: "Owner",
            dataIndex: "email",
            render: (text, record, i) => text,
            filters: dataSource.map(sel => sel.email).filter(onlyUnique).map(email => ({text: email, value: email})),
            onFilter: (email, record) => record.email === email
        },
        {
            title: "Last Updated", key: "ut", dataIndex: "ut", sorter: (a, b) => 1,
            sortOrder: sortedInfo.columnKey === 'ut' && sortedInfo.order,
            render: (text, record, index) => text.toLocaleString()
        }
    ];
    
    const footer = currentPageData => {
        const id = selectedRowKeys[0];
        const disable = selectedRowKeys.length === 0;
        return <Row gutter={5}>
            <Col offset={8} span={4}>
                <Button disabled={disable} type="primary" icon="reload"
                        onClick={() => history.push(`/selection/${id}`)}>Load Selection</Button>
            </Col>
            <Col span={4}>
                <Popconfirm title="Are you sure you want to delete this Selection?"
                            onConfirm={e => deleteSelection(id)}>
                    <Button disabled={disable} type="danger" icon="delete" ghost>Delete Selection</Button>
                </Popconfirm>
            </Col>
        </Row>
    };
    
    const onRow = (record) => ({
        onClick: () => onSelectChange([record.id])
    });
    
    return <Table loading={loading || deleting} footer={footer} rowSelection={rowSelection} dataSource={dataSource}
                  columns={columns} onChange={onChange} onRow={onRow} rowKey={record => record.id}/>
};

ArchiveTable.propTypes = {
    loading: PropTypes.bool.isRequired,
    deleting: PropTypes.bool.isRequired,
    dataSource: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    deleteSelection: PropTypes.func.isRequired,
    sortedInfo: PropTypes.object.isRequired,
    selectedRowKeys: PropTypes.array.isRequired,
    onSelectChange: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired
};

export default ArchiveTable