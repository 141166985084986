import * as React from "react";
import PropTypes from "prop-types"
import {Badge, Button, Card, Col, Form, Input, Row} from 'antd';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as LiteralActions from "../reducers/literal";

const {TextArea} = Input;
const FormItem = Form.Item;

const formItemLayout = {
    labelCol: {span: 3},
    wrapperCol: {span: 10},
};
const buttonItemLayout = {
    wrapperCol: {span: 18, offset: 6}
};

class LiteralSelection extends React.Component {
    
    onChange = e => {
        const input = e.target.value;
        const ids = (input.match(/[^\r\n]+/g) || [])
            .map(id => id.trim())
            .filter(id => id != "");
        
        this.props.setIds(ids);
    };
    
    download = e => {
        e.preventDefault();
        const {form, download} = this.props;
        form.validateFields(async (err, values) => {
            if (!err) {
                await download();
            }
        });
    };
    
    render() {
        const {ids, downloading, form} = this.props;
        const {getFieldDecorator} = form;
        return <div>
            <Card title="Literal">
                <Row>
                    <Col span={5}>
                        <p>Paste your IDs from Excel in the text area to the right. </p>
                        <br/>
                        <p>Important: Currently only aPureBase IDs are supported. </p>
                        <p>Make sure that there are no country prefixes either.</p>
                    
                    </Col>
                    
                    <Col span={10}>
                        <Form onSubmit={this.download} layout="horizontal">
                            <FormItem label="IDs" {...formItemLayout}>
                                {
                                    getFieldDecorator('ids', {
                                        rules: [{required: true}]
                                    })(<TextArea rows={5} onChange={this.onChange}/>)
                                }
                            </FormItem>
                            <FormItem {...buttonItemLayout}>
                                <Badge count={ids.length} overflowCount={99999}>
                                    <Button loading={downloading} disabled={ids.length === 0} type="primary"
                                            htmlType="submit">Download</Button>
                                </Badge>
                            </FormItem>
                        </Form>
                    </Col>
                </Row>
            </Card>
        </div>
    }
}

LiteralSelection.propTypes = {
    country: PropTypes.object,
    //title: PropTypes.string.isRequired,
    ids: PropTypes.array.isRequired,
    setIds: PropTypes.func.isRequired,
    download: PropTypes.func.isRequired,
    downloading: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
    ids: state.literal.ids,
    country: state.selection.country,
    downloading: state.literal.downloading
});

const mapDispatchToPros = dispatch => ({
    setIds: bindActionCreators(LiteralActions, dispatch).setIds,
    download: bindActionCreators(LiteralActions, dispatch).download
});

export default connect(mapStateToProps, mapDispatchToPros)(Form.create()(LiteralSelection))