import React from "react";
import {Form, Input} from "antd"

const FormItem = Form.Item;

const TemplateSettings = ({personTemplateId, siteTemplateId, getFieldDecorator, formItemLayout}) => {
    
    return <div>
        <FormItem label="Person Template Id" {...formItemLayout}>
            {
                getFieldDecorator('personTemplateId', {
                    rules: [{required: true}],
                    initialValue: personTemplateId || 1
                })(
                    <Input />
                )
            }
        </FormItem>
        <FormItem label="Site Template Id" {...formItemLayout}>
            {
                getFieldDecorator('siteTemplateId', {
                    rules: [{required: true}],
                    initialValue: siteTemplateId || 2
                })(
                    <Input />
                )
            }
        </FormItem>
    </div>
    
};

export default TemplateSettings