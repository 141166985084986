import Countries from "../constants/Countries"
import {SELECTION_DELETE, SELECTION_GET, SELECTION_SAVE} from "./archive"
import {autoCount} from "./utility";
import {getCount} from "./wizard"
import {handleLovAdd, handleLovDel} from "./reducerLogic";

const initialState = {
    id: null,
    country: null,
    title: '',
    type: 'person',
    LINK_SPC: [],
    PERS_TYPE: [],
    EDUCATION: [],
    POSITION: [],
    SITE_SPC: [],
    SITE_TYPE: [],
    PERS_KOL: [],
    SITE_KOL: [],
    SP_FILTER: [],
    BRICK: [],
    MINI: [],
    MICRO: [],
    FARMMO: []
};

export const COUNTRY_SET = "COUNTRY_SET";
const TITLE_SET = "TITLE_SET";
const SELECTION_NEW = "SELECTION_NEW";
export const LOV_ADD = "LOV_ADD";
export const LOV_REMOVE = "LOV_REMOVE";
export const TYPE_SET = "TYPE_SET";

export default (state = initialState, action) => {
    switch (action.type) {
        case TITLE_SET:
            return {...state, title: action.title};
        case COUNTRY_SET:
            const {country, setDefaultType} = action;
            if (setDefaultType) {
                const PERS_TYPE = country.defaultPersType ? [country.defaultPersType] : [];
                return {...state, country, PERS_TYPE};
            } else return {...state, country};
        case SELECTION_NEW:
            return initialState;
        case LOV_ADD:
            return handleLovAdd(state, action);
        case LOV_REMOVE:
            return handleLovDel(state, action);
        case TYPE_SET:
            return {...state, type: action.selectionType};
        case SELECTION_GET:
            if (action.status === 1) {
                const {
                    id, country, title, type, lovs
                } = action.selection;
                const {
                    LINK_SPC, PERS_TYPE, EDUCATION, POSITION, SITE_SPC, SITE_TYPE,
                    BRICK, MINI, MICRO, FARMMO, PERS_KOL, SITE_KOL, SP_FILTER
                } = lovs;
                // Remember to add new LOV types here
                return {
                    id, country: Countries[country], title, type,
                    LINK_SPC: LINK_SPC || [],
                    PERS_TYPE: PERS_TYPE || [],
                    EDUCATION: EDUCATION || [],
                    POSITION: POSITION || [],
                    SITE_SPC: SITE_SPC || [],
                    SITE_TYPE: SITE_TYPE || [],
                    BRICK: BRICK || [],
                    MINI: MINI || [],
                    MICRO: MICRO || [],
                    FARMMO: FARMMO || [],
                    PERS_KOL: PERS_KOL || [],
                    SITE_KOL: SITE_KOL || [],
                    SP_FILTER: SP_FILTER || []
                }
            }
            else return state;
        case SELECTION_SAVE:
            if (action.status === 1) {
                const {id, title} = action.selection;
                return {...state, id, title};
            } else return state;
        case SELECTION_DELETE:
            if (action.status === 1 && state.id === action.id) {
                // delete succeeded and the deleted one is currently selected
                return {...state, id: null}
            } else return state;
        default:
            return state;
    }
}

const countrySet = (country, setDefaultType) => ({type: COUNTRY_SET, country, setDefaultType});
export const setCountry = (country, setDefaultType, doCount) => (dispatch) => {
    dispatch(countrySet(country, setDefaultType));
    if (doCount) dispatch(getCount());
};
export const setTitle = title => ({type: TITLE_SET, title});
export const newSelection = () => ({type: SELECTION_NEW});

const lovAdd = (lovType, lovs) => ({type: LOV_ADD, lovType, lovs});
export const addLovs = (lovType, lovs) => (dispatch, getState) => {
    dispatch(lovAdd(lovType, lovs));
    autoCount(dispatch, getState)
};

const lovRemove = (lovType, lovs) => ({type: LOV_REMOVE, lovType, lovs});
export const removeLovs = (lovType, lovs) => (dispatch, getState) => {
    dispatch(lovRemove(lovType, lovs));
    autoCount(dispatch, getState)
};

const typeSet = selectionType => ({type: TYPE_SET, selectionType});
export const setType = selectionType => (dispatch, getState) => {
    dispatch(typeSet(selectionType));
    autoCount(dispatch, getState)
};