import * as React from "react";
import PropTypes from "prop-types"
import {connect} from "react-redux";
import {compose} from 'recompose';
import {Badge, Button, Col, Row, Table} from "antd"
import Countries, {Country} from "../../constants/Countries"
import LovTypes from "../../constants/LovTypes"
import * as SettingsActions from "../../reducers/settings";
import {bindActionCreators} from "redux";

const getType = key => LovTypes.find(t => t.type === key);

const getColumns = ({accounts, isApureBase}) => {
    const availableCountries = isApureBase ? Object.keys(Countries) : (accounts[0] ? accounts[0].countries : []);
    const availableLovs = isApureBase ? LovTypes : (accounts[0] ? LovTypes.filter(l => accounts[0].lovs.includes(l.type)) : []);
    return [
        {title: "First Name", key: "firstname", dataIndex: "firstname"},
        {title: "Last Name", key: "lastname", dataIndex: "lastname"},
        {
            title: "Email", key: "email", dataIndex: "email"
        },
        {
            title: "Account", key: "accountId", dataIndex: "accountId",
            render: (accountId, record, i) => <span>{accounts.find(acc => acc.id === accountId).name}</span>,
            filters: accounts.map(acc => ({text: acc.name, value: acc.id})), // TODO make all accounts available for APB
            onFilter: (value, user) => user.accountId === value
        },
        {
            title: "Role", key: "role", dataIndex: "role",
            filters: [{text: "Admin", value: "Admin"}, {text: "User", value: "User"}],
            onFilter: (role, user) => user.role === role
        },
        {
            title: "Status",
            key: "locked",
            dataIndex: "locked",
            render: (locked, record, i) => <Badge status={locked ? "error" : "success"}
                                                  text={locked ? "Locked" : "Active"}/>,
            filters: [{text: "Active", value: false}, {text: "Locked", value: true}],
            onFilter: (value, user) => user.locked === value,
        },
        {
            title: "Countries", key: "country", dataIndex: "countries",
            render: (array, record, index) => <p>{array.map(c => Country.getFlag(c))}</p>,
            filters: availableCountries.map(key => ({
                text: <span>{Country.getFlag(key)} {Countries[key].name}</span>, value: key
            })),
            onFilter: (country, account) => account.countries.includes(country.toUpperCase())
        },
        {
            title: "LOVs", key: "lovs", dataIndex: "lovs",
            render: (array, record, index) => <p>{array.map(t => getType(t)).map(t => t.getImage())}</p>,
            filters: availableLovs.map(l => ({text: <span>{l.getImage()} {l.title}</span>, value: l.type})),
            onFilter: (lov, account) => account.lovs.includes(lov)
        }
    ];
};


class UserManager extends React.Component {

    state = {
        selectedRowKeys: []
    };

    componentDidMount() {
        this.props.getAccounts();
    };

    onSelectChange = (selectedRowKeys) => this.setState({selectedRowKeys});

    onRow = (record) => ({
        onClick: () => this.onSelectChange([record.id])
    });

    render() {
        const {users, toggleUserEditor, fetching, loading} = this.props;
        const {selectedRowKeys} = this.state;

        const columns = getColumns(this.props);
        const disable = selectedRowKeys.length === 0;
        const rowSelection = {
            type: 'radio',
            selectedRowKeys,
            onChange: this.onSelectChange,
        };

        
        const footer = currentPageData => {
            const id = selectedRowKeys[0];
            return <Row gutter={5}>
                <Col offset={8} span={4}>
                    <Button loading={loading} icon="plus" type="primary" onClick={toggleUserEditor}>Create</Button>
                </Col>
                <Col span={4}>
                    <Button loading={loading} disabled={disable} icon="edit"
                            onClick={e => toggleUserEditor(e, id)}>Edit</Button>
                </Col>
            </Row>
        };
        
        return <div><Table loading={fetching} columns={columns} dataSource={users} rowKey="id" footer={footer}
                           rowSelection={rowSelection}
                           onRow={this.onRow}/>
        
        </div>
    }
    
    
}

UserManager.propTypes = {
    users: PropTypes.array.isRequired,
    accounts: PropTypes.array.isRequired,
    getAccounts: PropTypes.func.isRequired,
    toggleUserEditor: PropTypes.func.isRequired,
    fetching: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    isApureBase: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
    accounts: state.settings.accounts,
    users: state.settings.users,
    fetching: state.settings.fetching,
    loading: state.settings.userEditing,
    isApureBase: state.auth.isApureBase,
});
const mapDispatchToProps = dispatch => ({
    getAccounts: bindActionCreators(SettingsActions, dispatch).getAccounts,
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
)(UserManager);