import React from "react";
import {Button, Modal, Table} from "antd";

const columns = [
    {title: 'Last Name', dataIndex: 'Last Name', key: 'name'},
    {title: 'First Name', dataIndex: 'First Name', key: 'age'},
    {title: "Title", dataIndex: "Title", key: "title"},
    {title: "Person Type", dataIndex: "Person Type", key: "position"},
    {title: "Education", dataIndex: "Education", key: "education"},
    {title: "Working Specialty", dataIndex: "Working Specialty", key: "wrkSpec"},
    {title: "Address", dataIndex: "Address Line 1", key: "address"},
    {title: "Mail Address", dataIndex: "Mail Address", key: "mailAddress"},
    {title: "Mail City", dataIndex: "Mail City", key: "mailCity"},
    {title: "Mail Zip", dataIndex: "Mail Post Code", key: "zip"}
];

class Preview extends React.PureComponent {
    
    state = {
        width: '0', height: '0'
    };
    
    componentDidMount = () => {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    };
    
    componentWillUnmount = () => window.removeEventListener('resize', this.updateWindowDimensions);
    
    updateWindowDimensions = () => this.setState({width: window.innerWidth, height: window.innerHeight});
    
    getPreview = e => {
        e.preventDefault();
        this.props.getPreview();
    };
    
    render() {
        const {visible, loading, data} = this.props;
        
        const footer = <div>
            <Button onClick={this.props.hide}>Close</Button>
            <Button loading={loading} onClick={this.getPreview}>Reload</Button>
            <Button onClick={(e) => console.log(e)}>Download</Button>
        </div>;
        
        return <Modal width={this.state.width - 100} title="Preview" visible={visible} footer={footer}
                      onCancel={this.props.hide} maskClosable={true}>
            
            <Table loading={loading} columns={columns} dataSource={data}/>
        
        </Modal>
    }
    
}


export default Preview