import * as React from "react";
import PropTypes from "prop-types"
import {withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as FormatActions from "../reducers/format"
import {Card, Col, Input, Row, Table} from "antd"
import TemplateDisplay from "./TemplateDisplay";
import {getFieldKey} from "../util/Utility";
import TemplateSideBar from "./TemplateSideBar";

const Search = Input.Search;

const existsIn = (one, two) => one.toLowerCase().indexOf(two.toLowerCase()) !== -1;
const onFilter = (value, record) => existsIn(record.name, value);

class ListFormat extends React.Component {
    
    static propTypes = {
        fetchProps: PropTypes.func.isRequired,
        fetchOverview: PropTypes.func.isRequired,
        fields: PropTypes.array.isRequired,
        templates: PropTypes.array.isRequired,
        selected: PropTypes.array.isRequired,
        addField: PropTypes.func.isRequired,
        removeField: PropTypes.func.isRequired,
        id: PropTypes.number,
        name: PropTypes.string.isRequired,
        fetchTemplate: PropTypes.func.isRequired,
        saveTemplate: PropTypes.func.isRequired,
        changeName: PropTypes.func.isRequired,
        changeSort: PropTypes.func.isRequired,
        changeHeader: PropTypes.func.isRequired,
        fetching: PropTypes.bool.isRequired,
        fetchingTemplate: PropTypes.bool.isRequired
    };
    
    state = {
        search: '',
        selectedRowKeys: [],
        selected: [],
        headers: []
    };
    
    componentDidMount() {
        const {fetchProps, fetchOverview, match, id, fetchTemplate} = this.props;
        fetchProps();
        fetchOverview();
        const urlId = match.params.id;
        // eslint-disable-next-line
        if (urlId && id != urlId) {
            fetchTemplate(urlId);
        }
    }
    
    loadTemplate = async id => {
        const {fetchTemplate, history} = this.props;
        await fetchTemplate(id);
        history.push(`/templates/${id}`)
    };
    
    setSearch = value => this.setState({search: value});
    
    onSelectChange = (record, selected, selectedRows) => {
        const {addField, removeField} = this.props;
        if (selected) addField(record);
        else removeField(record);
    };
    
    onChangeName = e => {
        this.props.changeName(e.target.value);
    };
    
    saveTemplate = () => this.props.saveTemplate(true);
    saveTemplateAs = () => this.props.saveTemplate(false);
    
    render() {
        const {fields, selected, name, id, templates, fetchingTemplate, fetching, removeField, changeSort, changeHeader} = this.props;
        const {search} = this.state;
        
        const columns = [
            {
                title: "Entity", key: "entity", dataIndex: "entity",
                filters: [{text: 'Person', value: 'Person'},
                    {text: 'Site', value: 'Site'}, {text: 'Link', value: 'Affiliation'}],
                onFilter: (entity, record) => entity === record.entity
            },
            {title: "Type", key: "type", dataIndex: "type"},
            {title: "Name", key: "name", dataIndex: "name", onFilter: onFilter, filteredValue: [search]},
            {title: "Header", key: "header", dataIndex: "header"}
        ];
        
        const title = currentPageData => <span>
            <Search onChange={e => this.setSearch(e.target.value)}/>
        </span>;
        
        const rowSelection = {
            selectedRowKeys: selected.map(getFieldKey),
            onSelect: this.onSelectChange
        };
        
        return <div style={{padding: 24}}>
            <TemplateDisplay selectedFields={selected} loading={fetchingTemplate} removeField={removeField}
                             changeSort={changeSort} changeHeader={changeHeader}/>
            <Row gutter={20}>
                <Col span={18}>
                    <Card>
                        <Table rowSelection={rowSelection} columns={columns} loading={fetching}
                               dataSource={fields} title={title} rowKey={getFieldKey}/>
                    
                    </Card>
                </Col>
                <Col span={6}>
                    <TemplateSideBar onChangeName={this.onChangeName} saveTemplate={this.saveTemplate}
                                     loadTemplate={this.loadTemplate} loading={fetchingTemplate} id={id}
                                     saveTemplateAs={this.saveTemplateAs} name={name} templates={templates}/>
                </Col>
            </Row>
        </div>
    }
    
}

const mapStateToProps = state => ({
    fields: state.format.fields,
    templates: state.format.templates,
    selected: state.format.selected,
    id: state.format.id,
    name: state.format.name,
    fetching: state.format.fetching,
    fetchingTemplate: state.format.fetchingTemplate
});

const mapDispatchToProps = dispatch => ({
    fetchProps: bindActionCreators(FormatActions, dispatch).fetchProps,
    fetchTemplate: bindActionCreators(FormatActions, dispatch).fetchTemplate,
    fetchOverview: bindActionCreators(FormatActions, dispatch).fetchOverview,
    addField: bindActionCreators(FormatActions, dispatch).addField,
    removeField: bindActionCreators(FormatActions, dispatch).removeField,
    changeName: bindActionCreators(FormatActions, dispatch).changeName,
    saveTemplate: bindActionCreators(FormatActions, dispatch).saveTemplate,
    changeSort: bindActionCreators(FormatActions, dispatch).changeSort,
    changeHeader: bindActionCreators(FormatActions, dispatch).changeHeader
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ListFormat))