import React from "react";
import PropTypes from "prop-types"
import {Button, Card, Input, Popconfirm, Tooltip, message} from "antd";
import SelectionTree from "./SelectionTree";
import Preview from "./Preview";
import OptionPane from "./OptionPane";
import {isSelectionEmpty} from "../../util/Utility"
import ActionBar from "./ActionBar";
import {connect} from "react-redux";
import * as WizardActions from "../../reducers/wizard";
import * as SelectionActions from "../../reducers/selection";
import {bindActionCreators} from "redux";
import * as ArchiveActions from "../../reducers/archive";

import "../../css/flags.css";


const confirmTitle = "Are you sure you want to discard all unsaved changes?";

class SelectionSider extends React.PureComponent {

    state = {
        showPreview: false,
        showDownload: false,
        editingTitle: false
    };

    hidePreview = () => this.setState({showPreview: false});

    handleDownloadMenu = this.props.toggleDownload;

    changeEdit = (e) => {
        const editingTitle = !this.state.editingTitle;
        const value = e.target.value;
        this.setState({editingTitle}, () => {
            if (editingTitle) this.titleInput.focus();
            else if (value) {
                if (value.length > 200) {
                    message.warn("Maximum allowed characters in selection name is 200");
                } else {
                    this.props.setTitle(value);
                }
            }
        });
    };

    render() {
        const {
            selection, loading, saving, handleSaveSelection, downloading, countInSelection,
            countTotal, counting, getPreview, previewData, availableLovs
        } = this.props;
        const countryFlag = selection.country ? selection.country.getFlag() : <span/>;
        const selectionIsEmpty = isSelectionEmpty(selection, availableLovs);

        console.log('SelectionSider:render');

        const titleElement = this.state.editingTitle ?
            <Tooltip title="Press enter or click somewhere else to save">
                <span>
                    {countryFlag}
                    <Input
                        className="title-field-input"
                        defaultValue={selection.title}
                        onPressEnter={e => this.changeEdit(e)}
                        onBlur={e => this.changeEdit(e)}
                        ref={(input) => {
                            this.titleInput = input
                        }}
                    />
                </span>
            </Tooltip>
            : <Tooltip title="Click to change the name" placement="topLeft">
                <span className="title-field" onClick={this.changeEdit}>{countryFlag} {selection.title}</span>
            </Tooltip>;

        const newSelectionButton = (
            <Popconfirm onConfirm={this.props.newSelection} placement="bottomLeft" title={confirmTitle}>
                <Button type="dashed" size="small">New Selection</Button>
            </Popconfirm>
        );

        return <div>
            <Card loading={loading} title={titleElement}
                  extra={newSelectionButton}>
                <div>
                    <Preview
                        visible={this.state.showPreview}
                        hide={this.hidePreview}
                        getPreview={getPreview}
                        data={previewData}
                        loading={loading}
                    />

                    <p style={{fontWeight: 'bold'}}>Create a list by choosing your criteria from the table on the
                        left</p>
                    <div className='selectTree'>
                        <SelectionTree/>
                    </div>
                </div>
                <br/>

                <OptionPane/>

            </Card>
            <ActionBar
                counting={counting}
                saving={saving}
                downloading={downloading}
                selectionIsEmpty={selectionIsEmpty}
                countTotal={countTotal}
                countInSelection={countInSelection}
                handleDownloadMenu={this.handleDownloadMenu}
                handleSaveSelection={handleSaveSelection}
            />
        </div>
    }

}

SelectionSider.propTypes = {
    selection: PropTypes.object.isRequired,
    counting: PropTypes.bool.isRequired,
    getCount: PropTypes.func.isRequired,
    countTotal: PropTypes.number.isRequired,
    countInSelection: PropTypes.number.isRequired,
    getPreview: PropTypes.func.isRequired,
    previewData: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    download: PropTypes.func.isRequired,
    downloading: PropTypes.bool.isRequired,
    saving: PropTypes.bool.isRequired,
    setTitle: PropTypes.func.isRequired,
    availableLovs: PropTypes.array.isRequired,
    toggleDownload: PropTypes.func.isRequired,
    handleSaveSelection: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    selection: state.selection,
    loading: state.wizard.loading,
    previewData: state.wizard.previewData,
    counting: state.wizard.counting,
    downloading: state.wizard.downloading,
    saving: state.archive.saving,
    countTotal: state.wizard.countTotal,
    countInSelection: state.wizard.countInSelection,
    availableLovs: state.auth.availableLovs
});

const mapDispatchToProps = dispatch => ({
    setTitle: bindActionCreators(SelectionActions, dispatch).setTitle,
    getPreview: bindActionCreators(WizardActions, dispatch).getPreview,
    getCount: bindActionCreators(WizardActions, dispatch).getCount,
    download: bindActionCreators(WizardActions, dispatch).download,
    saveSelection: bindActionCreators(ArchiveActions, dispatch).save,
    getSelection: bindActionCreators(ArchiveActions, dispatch).getSelection
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectionSider)
