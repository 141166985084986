import React from "react"
import {Button, Card, Col, Row} from "antd"
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import PropTypes from "prop-types";
import * as DashboardActions from "../reducers/dashboard";
import {bindActionCreators} from "redux";
import ArchiveGrid from "./ArchiveGrid";
import NewsList from "./NewsList";
import ColleagueList from "./ColleagueList";
import {WithFooter} from "../authentication/WithFooter";
import NewsEditor from "./NewsEditor";
import * as Showdown from "showdown";
import {NewsModal} from "./NewsModal";
import * as SelectionActions from "../reducers/selection";

const defaultNews = {date: new Date(), title: '', type: 'info', content: ''};

class Home extends React.PureComponent {

    converter;

    constructor(props) {
        super(props);
        this.converter = new Showdown.Converter({tables: true, simplifiedAutoLink: true});
    }

    componentDidMount() {
        this.props.loadDashboard();
    }

    state = {
        archiveFilter: {name: null, email: null},
        showEditor: false,
        showModal: false,
        newsItem: defaultNews,
        isEdit: false
    };

    changeArchiveFilter = (name, email) => {
        if (this.state.archiveFilter.email === email) this.setState({archiveFilter: {}});
        else this.setState({archiveFilter: {name, email}})
    };

    toggleEditor = () => {
        this.setState(state => ({showEditor: !state.showEditor, isEdit: false}))
    };

    toggleNewsModal = (item) => {
        this.setState(state => ({showModal: !state.showModal, newsItem: item || defaultNews}))
    };

    editNewsItem = () => {
        this.setState({showModal: false, showEditor: true, isEdit: true})
    };

    onNewSelection = () => {
        const { newSelection, history } = this.props;
        newSelection();
        history.push('/selection');
    }

    render() {
        const {
            archive, loadingArchive, userEmail, users, news, fetching, isApureBase, saveNews, userRole, deleteNews,
            updateNews, saving
        } = this.props;
        const {archiveFilter, showEditor, showModal, newsItem, isEdit} = this.state;
        const currentUser = users.filter(user => user.email === userEmail)[0] || {};
        const isAdminAndApureBase = isApureBase && userRole === "Admin";
        const title = (
            <Row>
                <Col span={4}>
                    Welcome to Pure Mailing, {currentUser.firstname} {currentUser.lastname}!
                </Col>
                <Col span={3} offset={17}>
                    <Button icon='plus' type="primary" onClick={this.onNewSelection}>
                        New Selection
                    </Button>
                </Col>
            </Row>
        );

        return (
            <WithFooter>
                <Card title={title} loading={fetching}>
                    <Row gutter={20}>
                        <Col span={11}>
                            <NewsList
                                news={news}
                                isApureBase={isApureBase}
                                toggleEditor={this.toggleEditor}
                                toggleNewsModal={this.toggleNewsModal}
                            />
                        </Col>
                        <Col span={12}>
                            <ColleagueList
                                users={users}
                                changeFilter={this.changeArchiveFilter}
                                userEmail={userEmail}
                                filter={archiveFilter}
                            />
                            <br/>
                            <ArchiveGrid archive={archive} loadingArchive={loadingArchive} filter={archiveFilter}/>
                        </Col>
                    </Row>
                </Card>
                <NewsEditor
                    visible={showEditor}
                    save={saveNews}
                    toggleEditor={this.toggleEditor}
                    converter={this.converter}
                    isEdit={isEdit}
                    newsItem={newsItem}
                    update={updateNews}
                    saving={saving}
                />
                <NewsModal
                    visible={showModal}
                    toggle={this.toggleNewsModal}
                    item={newsItem}
                    converter={this.converter}
                    isAdminAndAPureBase={isAdminAndApureBase}
                    deleteNews={deleteNews}
                    editNewsItem={this.editNewsItem}
                />
            </WithFooter>
        );
    }
}

Home.propTypes = {
    loadDashboard: PropTypes.func.isRequired,
    fetching: PropTypes.bool.isRequired,
    archive: PropTypes.array.isRequired,
    userName: PropTypes.string.isRequired,
    userEmail: PropTypes.string.isRequired,
    users: PropTypes.array.isRequired,
    news: PropTypes.array.isRequired,
    isApureBase: PropTypes.bool.isRequired,
    saveNews: PropTypes.func.isRequired,
    userRole: PropTypes.string.isRequired,
    deleteNews: PropTypes.func.isRequired,
    updateNews: PropTypes.func.isRequired,
    saving: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
    archive: state.archive.store,
    loadingArchive: state.archive.loading,
    deleting: state.archive.deleting,
    userName: state.auth.userName,
    userEmail: state.auth.email,
    users: state.dashboard.users,
    news: state.dashboard.news,
    fetching: state.dashboard.fetching,
    isApureBase: state.auth.isApureBase,
    userRole: state.auth.role,
    saving: state.dashboard.saving
});

const mapDispatchToProps = dispatch => ({
    loadDashboard: bindActionCreators(DashboardActions, dispatch).fetchData,
    saveNews: bindActionCreators(DashboardActions, dispatch).saveNews,
    deleteNews: bindActionCreators(DashboardActions, dispatch).deleteNews,
    updateNews: bindActionCreators(DashboardActions, dispatch).updateNews,
    newSelection: bindActionCreators(SelectionActions, dispatch).newSelection,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home))
