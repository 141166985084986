import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";
import * as ArchiveActions from "../reducers/archive";
import {Card} from "antd";
import ArchiveTable from "./ArchiveTable";

class Archive extends React.Component {
    
    state = {
        selectedRowKeys: [],  // Check here to configure the default column
        filteredInfo: null,
        sortedInfo: {columnKey: 'ut', order: 'ascend'}
    };
    
    componentDidUpdate(prevProps) {
        // clear selected if no longer in data
        const key = this.state.selectedRowKeys[0];
        if (key && !this.props.archive.some(sel => sel.id === key)) {
            this.setState({selectedRowKeys: []});
        }
    }
    
    componentDidMount() {
        this.props.loadArchive()
    };
    
    onSelectChange = (selectedRowKeys) => {
        this.setState({selectedRowKeys});
    };
    
    handleChange = (pagination, filters, sorter) => this.setState({
        filteredInfo: filters,
        sortedInfo: sorter,
    });
    
    deleteSelection = id => this.props.deleteSelection(id);
    
    render() {
        const {history, loading, deleting, archive} = this.props;
        let {selectedRowKeys, sortedInfo} = this.state;
        sortedInfo = sortedInfo || {};
        
        return <Card>
            <ArchiveTable loading={loading} deleting={deleting} dataSource={archive}
                          onChange={this.handleChange} sortedInfo={sortedInfo} deleteSelection={this.deleteSelection}
                          selectedRowKeys={selectedRowKeys} onSelectChange={this.onSelectChange} history={history}/>
        </Card>
    }
}

Archive.propTypes = {
    loadArchive: PropTypes.func.isRequired,
    archive: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    deleting: PropTypes.bool.isRequired,
    deleteSelection: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    archive: state.archive.store,
    loading: state.archive.loading,
    deleting: state.archive.deleting
});

const mapDispatchToProps = dispatch => ({
    loadArchive: bindActionCreators(ArchiveActions, dispatch).loadArchive,
    deleteSelection: bindActionCreators(ArchiveActions, dispatch).deleteSelection
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Archive))