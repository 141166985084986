import axios from "axios"
import {downloadPath, evalError, getConfig} from "./utility";
import Countries from "../constants/Countries";

const initialState = {
    country: Countries.NO,
    title: '',
    ids: [],
    downloading: false
};

const IDS_SET = "IDS_SET";
const DOWNLOAD = "DOWNLOAD";

export default function (state = initialState, action) {
    switch (action.type) {
        case IDS_SET:
            return {...state, ids: action.ids};
        case DOWNLOAD:
            switch (action.status) {
                case 0:
                    return {...state, downloading: true};
                case 1:
                    return {...state, downloading: false};
                default:
                    return {...state, downloading: false};
            }
        default:
            return state;
    }
}


export const setIds = ids => ({type: IDS_SET, ids});

const downloadRequest = () => ({type: DOWNLOAD, status: 0});
const downloadReceive = filePath => ({type: DOWNLOAD, status: 1, filePath});
const downloadError = error => ({type: DOWNLOAD, status: -1, error});
export const download = () => (dispatch, getState) => {
    dispatch(downloadRequest());
    const info = getState().literal;
    axios.post('/api/selection/literal', info, getConfig(getState))
        .then(response => {
            const url = downloadPath(response.data);
            dispatch(downloadReceive(url))
        })
        .catch(error => evalError(dispatch, downloadError, error))
};