import React from "react"
import PropTypes from "prop-types"
import {Avatar, Card, Icon, Tooltip} from "antd"

const colorList = ['#f56a00', '#7265e6', '#ffbf00', '#00a2ae'];

const ColleagueList = ({users, changeFilter, userEmail, filter}) => {
    
    const getFullName = col => `${col.firstname} ${col.lastname}`;
    const getColor = email => filter.email === email ? {backgroundColor: colorList[0]} : {};
    
    const title = <span>You & your Colleagues <Tooltip
        title="Click one of the names to see their selections. Click again to see all.">
        <Icon type='question-circle-o'/>
    </Tooltip>
    </span>;
    
    return <Card title={title} type="inner">
        <a onClick={() => changeFilter('You', userEmail)}><Avatar size="large" style={getColor(userEmail)}>You</Avatar></a>
        {users
            .filter(user => user.email !== userEmail)
            .map(col => <Tooltip title={getFullName(col)} key={col.email}>
                    <a onClick={() => changeFilter(getFullName(col), col.email)}>
                        <Avatar style={getColor(col.email)} size="large">{col.firstname}</Avatar>
                    </a>
                </Tooltip>
            )}
    </Card>
};

ColleagueList.propTypes = {
    users: PropTypes.array.isRequired,
    changeFilter: PropTypes.func.isRequired,
    userEmail: PropTypes.string.isRequired,
    filter: PropTypes.object.isRequired
};

export default ColleagueList