import * as React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as SettingsActions from "../reducers/settings";
import PropTypes from "prop-types";
import {Icon, Layout, Menu} from "antd";
import {compose} from 'recompose';
import ChangePassword from "./ChangePassword";
import UserEditor from "./user/UserEditor";
import AccountConfiguration from "./account/AccountConfiguration";
import {NavLink, Route, withRouter} from "react-router-dom"
import Overview from "./Overview";
import AccountEditor from "./account/AccountEditor";
import UserManager from "./user/UserConfiguration";
import AccessConfiguration from "./access/AccessConfiguration";

const MenuItemGroup = Menu.ItemGroup;
const SubMenu = Menu.SubMenu;
const {Sider, Content} = Layout;

class Settings extends React.Component {
    
    state = {
        selected: {props: {title: "Change Password"}},
        selectedKey: "1",
        showEditor: false,
        editorAction: null,
        showUserEditor: false,
        userEditorAction: null
    };
    
    changeShow = (e, action) => {
        const show = this.state.showEditor;
        this.setState({showEditor: !show, editorAction: action})
    };
    
    toggleUserEditor = (e, action) => {
        const show = this.state.showUserEditor;
        this.setState({showUserEditor: !show, userEditorAction: action})
    };
    
    onSelect = (item, key, selectedKeys) => {
        this.setState({selected: item.item, selectedKey: item.key});
    };
    
    makeItem = ({
        url,
        title,
        requiredRole = this.props.role,
        requiredAccount = this.props.accountId,
    }) => {
        const {accountId, role, match} = this.props;
        if (requiredAccount === accountId && requiredRole === role) {
            return <Menu.Item key={url}>
                <NavLink to={`${match.url}/${url}`}>{title}</NavLink>
            </Menu.Item>
        }
        return null
    };
    
    makeRoute = (name, render) => {
        const path = `${this.props.match.url}/${name}`;
        return <Route path={path} exact render={render}/>
    };
    
    render() {
        const {
            accounts, loading, createAccount, creating, role, accountId,
            location, username, email, isApureBase
        } = this.props;
        const {makeRoute} = this;
        const {showEditor, showUserEditor, editorAction, userEditorAction} = this.state;

        return <Layout>
            <Sider>
                <Menu
                    mode='inline'
                    theme="dark"
                    selectedKeys={[location.pathname.split("/").pop()]}
                    defaultOpenKeys={["public", "private"]}
                    onSelect={this.onSelect}
                >
                    <MenuItemGroup key="settings" title="Settings">
                        <SubMenu key="public" title={<span><Icon type="user"/>Account</span>}>
                            {this.makeItem({url: "password", title: "Change Password"})}
                        </SubMenu>
                        
                        {role === 'Admin' ?
                            <SubMenu key="private" title={<span><Icon type="team"/>User Management</span>}>
                                {this.makeItem({
                                    url: "users",
                                    title: "User Configuration",
                                    requiredRole: "Admin",
                                })}
                                {this.makeItem({
                                    url: "accounts",
                                    title: "Account Configuration",
                                    requiredRole: "Admin",
                                    requiredAccount: 1,
                                })}
                                {this.makeItem({
                                    url: 'access',
                                    title: "Access Configuration",
                                    requiredRole: "Admin",
                                })}
                            </SubMenu>
                            : ''
                        }
                    </MenuItemGroup>
                </Menu>
            </Sider>
            <Layout>
                <Content>
                    {makeRoute("", () => <Overview accountId={accountId} username={username} email={email}/>)}
                    
                    {makeRoute("password", () => <ChangePassword loading={loading}/>)}

                    {role === 'Admin' && <span>
                            {makeRoute("users", () => <UserManager
                                toggleUserEditor={this.toggleUserEditor}
                            />)}
                        {makeRoute("access", () => <AccessConfiguration />)}

                        {isApureBase && <span>
                                {makeRoute("accounts", () => <AccountConfiguration
                                    showEditor={this.changeShow}
                                />)}
                            
                            <AccountEditor
                                visible={showEditor}
                                creating={creating}
                                createAccount={createAccount}
                                close={this.changeShow}
                                accountId={editorAction}
                                accounts={accounts}
                            />
                                
                            </span>
                        }
                        
                        <UserEditor
                            visible={showUserEditor}
                            editUserId={userEditorAction}
                            showAccountEditor={this.changeShow}
                            close={this.toggleUserEditor}
                        />
                        
                            
                        </span>
                    }
                </Content>
            </Layout>
        
        </Layout>
    }
}

Settings.propTypes = {
    role: PropTypes.string.isRequired,
    accountId: PropTypes.number.isRequired,
    accounts: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    createAccount: PropTypes.func.isRequired,
    creating: PropTypes.bool.isRequired,
    username: PropTypes.string,
    email: PropTypes.string.isRequired,
    isApureBase: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
    accounts: state.settings.accounts,
    loading: state.settings.loading,
    creating: state.settings.creating,
    role: state.auth.role,
    username: state.auth.username,
    email: state.auth.email,
    accountId: state.auth.accountId,
    isApureBase: state.auth.isApureBase,
});

const mapDispatchToProps = dispatch => ({
    changePassword: bindActionCreators(SettingsActions, dispatch).changePassword,
    resetPassword: bindActionCreators(SettingsActions, dispatch).resetPassword,
    createAccount: bindActionCreators(SettingsActions, dispatch).createAccount,
});

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
)(Settings);