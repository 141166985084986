import React, {Component} from 'react';
import {AutoComplete, Form, Icon, Input, Spin} from 'antd';
import PropTypes from "prop-types";
import {formItemLayout} from "../UserEditor";


const autocompleteFilter = (inputValue, option) => {
    if (!inputValue) return true;
    const p = option && option.props && option.props.person;

    const check = prop => p && p[prop].toLowerCase().indexOf(inputValue.toLowerCase()) !== -1;
    return check('firstname') || check('lastname') || check('email');
};

class Email extends Component {

    render() {
        const {
            initialValue, onSelect, options = [], loading,
            disabled = false,
            form: {getFieldDecorator}
        } = this.props;

        const optionsList = loading
            ? ([<AutoComplete.Option key="email-options-loading" disabled>
                <Spin size="small" /> Loading unregistered available users
            </AutoComplete.Option>])
            : options.map(p => (
                <AutoComplete.Option key={p.email} value={p.email} person={p}>
                    {p.email}
                </AutoComplete.Option>
            ));

        const autoCompleteField = (
            <AutoComplete
                disabled={disabled}
                dataSource={optionsList}
                onSelect={onSelect}
                filterOption={autocompleteFilter}
                allowClear
            >
                <Input type="email" prefix={<Icon type="mail" style={{fontSize: 13}}/>}/>
            </AutoComplete>
        );

        return (
            <Form.Item label="Email Address" {...formItemLayout} >
                {getFieldDecorator('email', {
                    rules: [
                        {required: true, message: 'Please enter a valid email!', whitespace: true},
                        {type: "email"},
                    ],
                    initialValue,
                })(autoCompleteField)}
            </Form.Item>
        );
    }
}

Email.propTypes = {
    initialValue: PropTypes.any,
    options: PropTypes.array,
    onSelect: PropTypes.func,
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
    form: PropTypes.shape({
        getFieldDecorator: PropTypes.func.isRequired,
    }).isRequired,
};

Email.defaultProps = {
    options: [],
    onSelect: null,
    loading: false,
    disabled: false,
};

Email.defaultProps = {
    initialValue: '',
};

export default Email;