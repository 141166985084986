import {message} from "antd"

const defaultDuration = 4;

export const error = (content) => {
    const finalContent = typeof content === 'object' ? content.message : content;
    message.error(finalContent, defaultDuration);
};

export const success = content => message.success(content, defaultDuration);

export const info = content => {
    message.info(content, defaultDuration);
};

export const warn = content => {
    console.warn(content);
    message.warn(content, defaultDuration)
};