import * as React from "react";
import PropTypes from "prop-types";
import {Badge, Button, Card, Col, Form, Icon, Input, Popconfirm, Row, Tooltip} from "antd";
import {connect} from "react-redux";
import {compose} from 'recompose';
import {bindActionCreators} from "redux";
import * as SettingsActions from "../reducers/settings";
import {confirmPasswordsRule, newPasswordRule} from "../util/formRules";

const FormItem = Form.Item;

const formItemLayout = {
    labelCol: {span: 6},
    wrapperCol: {span: 14},
};
const buttonItemLayout = {
    wrapperCol: {span: 18, offset: 6}
};

class ChangePassword extends React.Component {
    
    handleSubmit = (e) => {
        e.preventDefault();
        const {form, changePassword} = this.props;
        form.validateFields(async (err, values) => {
            if (!err) {
                const result = await changePassword(values);
                if (!result.error) form.resetFields();
            }
        });
    };
    
    handleReset = e => {
        this.props.resetPassword();
    };

    changePassword = ({getFieldDecorator, getFieldValue}) => [
        <Col span={10} key="change-password">
            <Form layout="horizontal" onSubmit={this.handleSubmit}>
                <FormItem label="Old Password" {...formItemLayout}>
                    {getFieldDecorator('oldpass', {
                        rules: [
                            {required: true, message: 'Please enter your old password!'}
                        ]
                    })(
                        <Input type="password"/>
                    )}
                </FormItem>
                <FormItem label="New Password" {...formItemLayout}>
                    {getFieldDecorator('newpass', newPasswordRule())(
                        <Input type="password"/>
                    )}
                </FormItem>
                <FormItem label="Confirm New Password" {...formItemLayout}>
                    {getFieldDecorator('confirm', confirmPasswordsRule(getFieldValue))(
                        <Input type="password"/>
                    )}
                </FormItem>
                <FormItem {...buttonItemLayout} >
                    <Row gutter={10}>
                        <Col span={12}>
                            <Button type="primary" htmlType="submit">Update password</Button>
                        </Col>
                        <Col span={12}>
                            <Popconfirm onConfirm={this.handleReset} title="Do you want to reset your password?">
                                <a style={{fontSize: 14}} href='http://apurebase.com/contact/support/'>I
                                    forgot
                                    my password</a>
                            </Popconfirm>
                        </Col>
                    </Row>

                </FormItem>
            </Form>
        </Col>,
        <Col span={8} key="whitespace"/>
    ];

    ldapMessage = () => (
        <Col span={10}>
            <div>
                <p>
                    Passwords is not handled by Mailing Tool.
                </p>
                <p>
                    You have the same password as you have in other applications handled by&nbsp;
                    <Tooltip
                        title="Active Directory is what aPureBase uses to handle passwords for anything from your OutLook account to our aPureBase accounts."
                        placement="topRight"
                    >
                        Active Directory <Icon type="info-circle"/>
                    </Tooltip>
                </p>
                <p>
                    To&nbsp;
                    <Tooltip title="Changing this will change your password on all your accounts">
                        change password
                    </Tooltip>
                    , you'll need to change your Active Directory password, which you can do by pressing
                    the following:&nbsp;
                    <Badge count={"CTRL"} style={{backgroundColor: '#a9a9a9'}}/>&nbsp;+&nbsp;
                    <Badge count={"ALT"} style={{backgroundColor: '#a9a9a9'}}/>&nbsp;+&nbsp;
                    <Badge count={"END"} style={{backgroundColor: '#a9a9a9'}}/>&nbsp;
                    in your Remote Desktop.
                </p>
            </div>
        </Col>
    );

    render() {
        const {form, hasApureBase} = this.props;
        return (
            <div>
                <Row>
                    <Card title="Change Password">
                        {hasApureBase ? this.ldapMessage() : this.changePassword(form)}
                    </Card>
                </Row>
            </div>
        );
    }
    
}

ChangePassword.propTypes = {
    changePassword: PropTypes.func.isRequired,
    resetPassword: PropTypes.func.isRequired,
    hasApureBase: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
    hasApureBase: state.auth.hasApureBase,
});

const mapDispatchToProps = dispatch => ({
    changePassword: bindActionCreators(SettingsActions, dispatch).changePassword,
    resetPassword: bindActionCreators(SettingsActions, dispatch).resetPassword,
});

export default compose(
    Form.create(),
    connect(mapStateToProps, mapDispatchToProps),
)(ChangePassword);
