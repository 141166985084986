import {combineReducers} from "redux";
import wizard from "./wizard";
import selection from "./selection";
import settings from "./settings";
import archive from "./archive";
import auth from "./auth";
import format from "./format"
import literal from "./literal"
import dashboard from "./dashboard"

const RESET = "RESET";

const appReducer = combineReducers({
    wizard, selection, archive, auth, settings, format, literal, dashboard
});

const rootReducer = (state, action) => {
    if (action.type === RESET) state = undefined;
    return appReducer(state, action);
};


export default rootReducer