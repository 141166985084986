import * as React from "react";
import PropTypes from "prop-types"
import {Avatar, Button, Col, Row, Table} from "antd"
import LovTypes from "../../constants/LovTypes"
import Countries, {Country} from "../../constants/Countries"
import {onlyUnique} from "../../util/Utility";
import * as SettingsActions from "../../reducers/settings";
import {bindActionCreators} from "redux";
import {compose} from "recompose";
import {connect} from "react-redux";

const getType = key => LovTypes.find(t => t.type === key);

class AccountConfiguration extends React.Component {
    
    state = {
        selectedRowKeys: []
    };
    
    componentDidMount() {
        this.props.getAccounts();
    }
    
    onSelectChange = (selectedRowKeys) => {
        this.setState({selectedRowKeys});
    };
    
    makeAvatar = (user) => {
        return <Avatar size="large" className={user.role} key={user.id}>{user.firstname}</Avatar>
    };
    
    render() {
        const {accounts, creating, showEditor} = this.props;
        const {selectedRowKeys} = this.state;
        const disable = selectedRowKeys.length === 0;
        const rowSelection = {
            type: 'radio',
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        const columns = [
            {title: "Name", key: "name", dataIndex: "name"},
            {
                title: "Key Account Manager", key: "kamEmail", dataIndex: "kamEmail",
                filters: accounts.map(acc => acc.kamEmail.toLowerCase()).filter(onlyUnique).map(email => ({text: email, value: email})),
                onFilter: (kamEmail, account) => account.kamEmail.toLowerCase() === kamEmail
            },
            {
                title: "Users", key: "users", dataIndex: "users",
                render: (array, record, i) => <span>{array.map(user => this.makeAvatar(user))}</span>
            },
            {
                title: "Countries",
                key: "countries",
                dataIndex: "countries",
                render: (array, record, index) => <p>{array.map(c => Country.getFlag(c))}</p>,
                filters: Object.keys(Countries).map(key => ({
                    text: <span>{Country.getFlag(key)} {Countries[key].name}</span>, value: key
                })),
                onFilter: (country, account) => account.countries.includes(country.toUpperCase())
            },
            {
                title: "LOVs",
                key: "lovs",
                dataIndex: "lovs",
                render: (array, record, index) => <p>{array.map(t => getType(t)).map(t => t.getImage())}</p>,
                filters: LovTypes.map(l => ({text: <span>{l.getImage()} {l.title}</span>, value: l.type})),
                onFilter: (lov, account) => account.lovs.includes(lov)
            }
        ];
        
        const footer = currentPageData => {
            const id = selectedRowKeys[0];
            return <Row gutter={5}>
                <Col offset={8} span={4}>
                    <Button loading={creating} icon="plus" type="primary" onClick={showEditor}>Create</Button>
                </Col>
                <Col span={4}>
                    <Button loading={creating} disabled={disable} icon="edit"
                            onClick={e => showEditor(e, id)}>Edit</Button>
                </Col>
            </Row>
        };
        
        const onRow = (record) => ({
            onClick: () => this.onSelectChange([record.id])
        });
        
        return <div>
            <Table
                loading={this.props.fetching}
                rowSelection={rowSelection}
                rowKey="id"
                columns={columns}
                dataSource={accounts}
                footer={footer}
                onRow={onRow}
            />
        </div>
    }
    
}

AccountConfiguration.propTypes = {
    getAccounts: PropTypes.func.isRequired,
    accounts: PropTypes.array.isRequired,
    createAccount: PropTypes.func.isRequired,
    creating: PropTypes.bool.isRequired,
    showEditor: PropTypes.func.isRequired,
    fetching: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
    accounts: state.settings.accounts,
    fetching: state.settings.fetching,
    creating: state.settings.creating,
});
const mapDispatchToProps = dispatch => ({
    getAccounts: bindActionCreators(SettingsActions, dispatch).getAccounts,
    createAccount: bindActionCreators(SettingsActions, dispatch).createAccount,
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
)(AccountConfiguration);
