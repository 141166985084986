import * as React from "react";
import PropTypes from "prop-types"
import {Col, Row} from "antd"
import RegisteredIPTable from "./RegisteredIPTable";
import LoginAttemptTable from "./LoginAttemptTable";
import {compose} from "recompose";
import {connect} from "react-redux";
import * as SettingsActions from "../../reducers/settings";
import {bindActionCreators} from "redux";

class AccessConfiguration extends React.Component {
    
    componentDidMount() {
        this.props.fetchIps();
        this.props.fetchLogins();
        this.props.getAccounts();
    }
    
    getUser = id => this.props.users.find(u => u.id === id);
    
    getAvatar = id => {
        const user = this.getUser(id);
        if (user) {
            const {email} = user;
            //const name = email.substr(0, email.indexOf('@'));
            //return <Avatar className={role} key={email} >{name}</Avatar>
            return email
        }
        else return id;
    };
    
    render() {
        const {ips, fetchingIps, logins} = this.props;
        
        return <Row style={{padding: 24}} gutter={20}>
            <Col span={11}>
                <RegisteredIPTable getAvatar={this.getAvatar} ips={ips} fetchingIps={fetchingIps}/>
            </Col>
            
            <Col span={11}>
                <LoginAttemptTable logins={logins} getAvatar={this.getAvatar}/>
            </Col>
        </Row>
    }
    
}

AccessConfiguration.propTypes = {
    ips: PropTypes.array.isRequired,
    fetchIps: PropTypes.func.isRequired,
    fetchingIps: PropTypes.bool.isRequired,
    logins: PropTypes.array.isRequired,
    fetchLogins: PropTypes.func.isRequired,
    users: PropTypes.array.isRequired,
    getAccounts: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    ips: state.settings.ips,
    fetchingIps: state.settings.fetchingIps,
    logins: state.settings.logins,
    users: state.settings.users,
});
const mapDispatchToProps = dispatch => ({
    getAccounts: bindActionCreators(SettingsActions, dispatch).getAccounts,
    fetchIps: bindActionCreators(SettingsActions, dispatch).fetchIps,
    fetchLogins: bindActionCreators(SettingsActions, dispatch).fetchLogins,
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
)(AccessConfiguration);