/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import {Col, Layout, Row} from "antd";
import {withRouter} from "react-router-dom";
import * as WizardActions from "../reducers/wizard";
import * as SelectionActions from "../reducers/selection";
import * as ArchiveActions from "../reducers/archive";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import SelectionSider from "./Selection/SelectionSider";
import CountryModal from "./CountryModal";
import DownloadDisclaimer from "./Selection/DownloadDisclaimer";
import SaveAsModal, {saveTypes} from "./Selection/SaveAsModal";
import LovMenu from "./Lov/LovMenu";
import LovTab from "./Lov/LovTab";
import SearchBar from "./Lov/SearchBar";

const {Sider, Content} = Layout;

const Mailing = ({
    history,
    location,
    match,
    selection,
    countries,
    loading,
    gettingSelection,
    download,
    downloading,
    saving,

    getSelection,
    newSelection: newSelectionProp,
    saveSelection,
    setCountry,
    setTitle,
    loadLovs,
}) => {
    const [showModal, setShowModal] = useState(false);
    const [showDownload, setShowDownload] = useState(false);
    const [printPack, setPrintPack] = useState(false);
    const [isNewSelection, setIsNewSelection] = useState(false);
    const [showSaveAs, setShowSaveAs] = useState(false);
    const [collapsed, setCollapsed] = useState(false);

    console.log('mailing.js');
    useEffect(() => {
        const {title, id: storeSelectionId} = selection;
        const urlId = match.params.id;
        // no id in url and title is not set -> show modal
        if (!urlId && title === '') {
            console.log('mailing.js', true);
            // when there is no id, show modal
            setShowModal(true);
        } else if (urlId && (!storeSelectionId || urlId != storeSelectionId)) {
            console.log('mailing.js', false);
            // url id but (no store id or ids differ) -> load
            getSelection(urlId);
        }

        return () => {
            // Remove all changes if user leaves page.
            const id = match.params.id || selection.id;
            if (id) getSelection(id);
        };
    }, []);


    const initializeSelection = (country, title) => {
        history.push("/selection");
        newSelectionProp();
        setCountry(country, true, true);
        setTitle(title);
        loadLovs(country);

        setShowModal(false);
    };

    const cancelModal = () => {
        if (isNewSelection) setShowModal(false);
        else history.goBack();
    };

    const toggleDownloadModal = e => {
        setPrintPack(e && e.key === "2");
        setShowDownload(!showDownload);
    };

    const handleSaveSelection = (saveType, selection) => {
        setShowSaveAs(saveType === saveTypes.SAVE_AS);
        if (saveType === saveTypes.SAVE) saveSelection(history, location, selection);
    };

    const newSelection = () => {
        setIsNewSelection(true);
        setShowModal(true);
    };

    const loadingAny = loading || showModal || gettingSelection;


    return (
      <Layout>
            <Sider
              collapsible
              collapsed={collapsed}
              onCollapse={setCollapsed}
              style={{overflow: 'auto', height: '100vh', position: 'fixed', left: 0}}
            >
                <LovMenu collapsed={collapsed} />
            </Sider>
            <Layout style={{marginLeft: collapsed ? 80 : 200}}>
                <Content style={{background: '#fff', minHeight: 400}}>
                    <Row gutter={14} style={{padding: 20}} className="mailing">
                        <CountryModal
                          showModal={showModal}
                          initializeSelection={initializeSelection}
                          cancel={cancelModal}
                          countries={countries}
                        />

                        <DownloadDisclaimer
                            visible={showDownload}
                            download={download}
                            toggleDownload={toggleDownloadModal}
                            loading={downloading}
                            printPack={printPack}
                        />

                        {showSaveAs && <SaveAsModal
                            selection={selection}
                            visible={true}
                            loading={saving}
                            handleSaveSelection={handleSaveSelection}
                        />}

                        <Col span={16}>
                            <div style={{paddingBottom: 10}}>
                                <SearchBar/>
                            </div>
                            <LovTab loading={loadingAny}/>
                        </Col>

                        <Col span={8}>
                            <SelectionSider
                                loading={loadingAny}
                                toggleDownload={toggleDownloadModal}
                                handleSaveSelection={handleSaveSelection}
                                newSelection={newSelection}
                            />
                        </Col>
                    </Row>
                </Content>
            </Layout>
        </Layout>
    );
};

Mailing.propTypes = {
    loadLovs: PropTypes.func.isRequired,
    selection: PropTypes.object.isRequired,
    setCountry: PropTypes.func.isRequired,
    setTitle: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    newSelection: PropTypes.func.isRequired,
    download: PropTypes.func.isRequired,
    saveSelection: PropTypes.func.isRequired,
    getSelection: PropTypes.func.isRequired,
    downloading: PropTypes.bool.isRequired,
    gettingSelection: PropTypes.bool.isRequired,
    countries: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
    selection: state.selection,
    loading: state.wizard.loading,
    downloading: state.wizard.downloading,
    saving: state.archive.saving,
    gettingSelection: state.archive.gettingSelection,
    countries: state.auth.countries,
});

const mapDispatchToProps = dispatch => ({
    setCountry: bindActionCreators(SelectionActions, dispatch).setCountry,
    setTitle: bindActionCreators(SelectionActions, dispatch).setTitle,
    newSelection: bindActionCreators(SelectionActions, dispatch).newSelection,
    loadLovs: bindActionCreators(WizardActions, dispatch).loadLovs,
    download: bindActionCreators(WizardActions, dispatch).download,
    saveSelection: bindActionCreators(ArchiveActions, dispatch).save,
    getSelection: bindActionCreators(ArchiveActions, dispatch).getSelection
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Mailing))
