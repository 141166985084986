/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react";
import PropTypes from "prop-types"
import {Collapse, Form, Icon, Input, Modal, Select, Switch, Tooltip} from "antd"
import {CountryOptions} from "../../constants/Countries"
import {LovOptionsNoBrick} from "../../constants/LovTypes"
import BrickSettings from "./BrickSettings";
import SelectionSettings from "./SelectionSettings";
import {formItemLayout} from "../../util/Utility"
import TemplateSettings from "./TemplateSettings";
import {compose} from "recompose";

const FormItem = Form.Item;

const globalPattern = {
    procedure: 'SP_{account}_MAILING_{country}_SELECTION',
    table: '{account}_MAILING_{country}_{type}_SELECTION'
};

const AccountEditor = ({
    form,
    accountId,
    createAccount,
    visible,
    close,
    accounts,
    creating,
}) => {
    const [bricks, setBricks] = useState([]);

    const handleReset = () => {
        form.resetFields();
        setBricks([]);
    };

    const handleOk = e => {
        e.preventDefault();
        form.validateFields(async (err, values) => {
            if (!err) {
                await createAccount({...values, id: accountId}, accountId);
                if (visible) close();
                handleReset();
            }
        });
    };

    useEffect(() => {
        handleReset();
    }, [accountId]);

    const addBrick = (country, type) => {
        setBricks([...bricks, {country, type}]);
    };

    const onGlobalChange = checked => {
        if (checked) {
            form.setFieldsValue({procedure: globalPattern.procedure, table: globalPattern.table});
        } else {
            form.setFieldsValue({procedure: null, table: null});
        }
    };


    const {getFieldDecorator} = form;
    let name = "";
    let countries, lovs = [];
    let kamEmail, procedure, table, code, personTemplateId, siteTemplateId, boxFolderId, downloadViaBox = null;
    let countryBricks = {};

    if (accountId) {
        // An Account is being edited, set up accordingly
        const account = accounts.find(acc => acc.id === accountId);
        name = account.name;
        kamEmail = account.kamEmail;
        countries = account.countries;
        lovs = account.lovs;
        procedure = account.procedure;
        table = account.table;
        code = account.code;
        countryBricks = account.bricks;
        personTemplateId = account.personTemplateId;
        siteTemplateId = account.siteTemplateId;
        boxFolderId = account.boxFolderId;
        downloadViaBox = account.downloadViaBox;
    }

    return (
        <Modal
            title="Account Editor"
            visible={visible}
            onCancel={close}
            onOk={handleOk}
            confirmLoading={creating}
        >
            <Form onSubmit={handleOk} layout="horizontal">
                <FormItem label="Name" {...formItemLayout}>
                    {
                        getFieldDecorator('name', {
                            rules: [{required: true, message: "Please specify a name"}],
                            initialValue: name
                        })(<Input/>)
                    }
                </FormItem>
                <FormItem label="KAM Email Address" {...formItemLayout} >
                    {getFieldDecorator('kamEmail', {
                        rules: [
                            {required: true, message: 'Please enter a valid email!', whitespace: true},
                            {type: "email"},
                        ],
                        initialValue: kamEmail
                    })(
                        <Input type="email" prefix={<Icon type="mail" style={{fontSize: 13}}/>}/>
                    )}
                </FormItem>
                <FormItem label="Box Folder ID" {...formItemLayout}>
                    {
                        getFieldDecorator('boxFolderId', {
                            rules: [{required: true, message: "Please specify a Box folder ID"}],
                            initialValue: boxFolderId,
                        })(
                          <Input prefix={<Icon type="folder" style={{fontSize: 13}} />}/>
                        )
                    }
                </FormItem>
                <FormItem
                    label={(
                    <span>
                        <span>Download via Box&nbsp;</span>
                        <Tooltip title="When enabled it will make the users download files through box.com instead of directly from aPureBase servers.">
                            <Icon type="question-circle-o"/>
                        </Tooltip>
                      </span>
                    )}
                    {...formItemLayout}
                >
                    {
                        getFieldDecorator('downloadViaBox', {
                            rules: [{required: true, message: "Please specify a Box folder ID"}],
                            initialValue: downloadViaBox,
                            valuePropName: 'checked',
                        })(
                            <Switch />
                        )
                    }
                </FormItem>
                <FormItem label="Countries" {...formItemLayout}>
                    {
                        getFieldDecorator('countries', {
                            rules: [{required: true, message: "Please specify at least one country"}],
                            initialValue: countries
                        })(
                            <Select mode="multiple" tokenSeparators={[',']}>
                                {CountryOptions}
                            </Select>
                        )
                    }
                </FormItem>
                <FormItem label="LOVs" {...formItemLayout}>
                    {
                        getFieldDecorator('lovs', {
                            rules: [{required: true, message: "Please specify at least one type of LOV"}],
                            initialValue: lovs
                        })(
                            <Select mode="multiple" tokenSeparators={[',']}>
                                {LovOptionsNoBrick}
                            </Select>
                        )
                    }
                </FormItem>

                <Collapse bordered={false} defaultActiveKey={['1', '2', '3']}>
                    {/*Keep all Panels active, since it has to render before previous values are read*/}
                    <Collapse.Panel header="Bricks" key="1">
                        <BrickSettings
                            countryBricks={countryBricks}
                            formItemLayout={formItemLayout}
                            countries={countries || []}
                            addedBricks={bricks}
                            addBrick={addBrick}
                            getFieldDecorator={getFieldDecorator}
                        />
                    </Collapse.Panel>
                    <Collapse.Panel header="Selection" key="2">
                        <SelectionSettings
                            table={table}
                            procedure={procedure}
                            code={code}
                            formItemLayout={formItemLayout}
                            globalPattern={globalPattern}
                            getFieldDecorator={getFieldDecorator}
                            onGlobalChange={onGlobalChange}
                        />
                    </Collapse.Panel>
                    <Collapse.Panel header="Templates" key="3">
                        <TemplateSettings
                            personTemplateId={personTemplateId}
                            siteTemplateId={siteTemplateId}
                            getFieldDecorator={getFieldDecorator}
                            formItemLayout={formItemLayout}
                        />
                    </Collapse.Panel>
                </Collapse>
            </Form>
        </Modal>
    );
};

AccountEditor.propTypes = {
    visible: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    createAccount: PropTypes.func.isRequired,
    creating: PropTypes.bool.isRequired,
    accounts: PropTypes.array.isRequired,
    accountId: PropTypes.number
};

export default compose(
    Form.create(),
)(AccountEditor)
