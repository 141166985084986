export class Brick {
    constructor(key, name) {
        this.key = key;
        this.name = name;
    }
    
}

export default [
    new Brick('BRICK', 'Brick'),
    new Brick('FARMMO', 'Farmmo'),
    new Brick('MINI', 'Mini'),
    new Brick('MICRO', 'Micro')
];