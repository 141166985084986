import React from "react"
import PropTypes from "prop-types"
import {Affix, Badge, Button, Card, Col, Dropdown, Icon, Menu, Row, Spin, Tooltip} from "antd"
import { saveTypes } from "./SaveAsModal";
import {withRouter} from "react-router-dom";


const dropdownItem = ({label, handle}, index) => (
    <Menu.Item key={index} onClick={handle}>{label}</Menu.Item>
);

const dropdown = (items) => (
    <Dropdown
        overlay={<Menu>{items.map(dropdownItem)}</Menu>}
        disabled={items[0].disabled}
    >
        <Button
            type={items[0].type}
            onClick={items[0].handle}
            loading={items[0].loading}
            icon={items[0].icon}
        >
            {items.length > 1 && <Icon type="down"/>}
            {items[0].label}
        </Button>
    </Dropdown>
);

const ActionBar = ({
   counting, selectionIsEmpty, downloading, saving, handleSaveSelection,
   countTotal, countInSelection, handleDownloadMenu
}) => {


    const downloadMenu = [
        {
            label: "Download",
            handle: handleDownloadMenu,
            type: "primary",
            icon: "download",
            loading: downloading,
            disabled: selectionIsEmpty
        },
        {label: "Download & Request Print, Pack and Deployment", handle: handleDownloadMenu},
    ];

    const saveMenu = [
        {label: "Save", handle: () => handleSaveSelection(saveTypes.SAVE), type: "primary", icon: "save", loading: saving},
        {label: "Save As...", handle: () => handleSaveSelection(saveTypes.SAVE_AS)},
    ];


    return <Affix offsetBottom={15}>
        <Card>
            <Spin spinning={counting}>
                <Row gutter={5}>
                    <Col span={6}>
                        <div className="selection-button-align">
                        <Tooltip title="The total number of records in aPureBase that match the current selection">
                            Total: <Badge count={countTotal} showZero={true} overflowCount={99999}
                                          style={{backgroundColor: '#a6a8ab'}}/>
                        </Tooltip></div>
                    </Col>
                    <Col span={6}>
                        <div className="selection-button-align"><Tooltip title="The number of records available for you">
                            Available: <Badge count={countInSelection} showZero={true} overflowCount={99999}
                                              style={{backgroundColor: '#108ee9'}}/>
                        </Tooltip>
                        </div>
                    </Col>
                    <Col span={12}>
                        <Button.Group>
                            {dropdown(downloadMenu)}
                            {dropdown(saveMenu)}
                        </Button.Group>
                    </Col>
                </Row>
            </Spin>
        </Card>
    </Affix>;

};

ActionBar.propTypes = {
    counting: PropTypes.bool.isRequired,
    downloading: PropTypes.bool.isRequired,
    saving: PropTypes.bool.isRequired,
    selectionIsEmpty: PropTypes.bool.isRequired,
    countTotal: PropTypes.number.isRequired,
    countInSelection: PropTypes.number.isRequired,
    handleDownloadMenu: PropTypes.func.isRequired,
    handleSaveSelection: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
};

export default withRouter(ActionBar)