import axios from "axios";
import LovTypes from "../constants/LovTypes";
import {error} from "../constants/Messaging";
import {compareLovs, downloadPath, evalError, getConfig, getSelection} from "./utility";
import {notification} from "antd";
import {isSelectionEmpty} from "../util/Utility";
import {determineCountInSelection} from "./reducerLogic";

const initialState = {
    lovsByType: {},
    loading: false,
    count: 0,
    countTotal: 0,
    countInSelection: 0,
    counting: false,
    downloading: false,
    filePath: null,
    selectedType: LovTypes[0].type,
    previewData: [],
    search: '',
    selectionId: null,
    changeCounter: 0
};

export const FETCH_LOVS = "FETCH_LOVS";
const COUNT_GET = "COUNT_GET";
const PREVIEW_GET = "PREVIEW_GET";
const TYPE_SELECT = "TYPE_SELECT";
const SEARCH_SET = "SEARCH_SET";
const DOWNLOAD = "DOWNLOAD";
const SELECTION_NEW = "SELECTION_NEW";
const ACTION_COUNT_INC = "ACTION_COUNT_INC";
const ACTION_COUNT_RESET = "ACTION_COUNT_RESET";

export default function (state = initialState, action) {
    switch (action.type) {
        case SELECTION_NEW:
            return initialState;
        case FETCH_LOVS:
            switch (action.status) {
                case 0:
                    return {...state, loading: true};
                case 1:
                    const {lovs, country} = action;
                    return {...state, country, lovsByType: lovs, loading: false, count: null};
                default:
                    error(`There was a problem fetching LOVs: ${action.error.message}`);
                    return {...state, loading: false};
            }
        case COUNT_GET:
            switch (action.status) {
                case 0:
                    return {...state, counting: true};
                case 1:
                    const {total, inSelection} = action.count;
                    return {
                        ...state,
                        counting: false,
                        countTotal: total,
                        countInSelection: determineCountInSelection(total, inSelection)
                    };
                default:
                    error(action.error.message);
                    return {...state, counting: false};
            }
        case PREVIEW_GET:
            switch (action.status) {
                case 0:
                    return {...state, loading: true};
                case 1:
                    return {...state, loading: false, previewData: action.data};
                default:
                    error(`There was a problem fetching the preview: ${action.error.message}`);
                    return {...state, loading: false};
            }
        case DOWNLOAD:
            switch (action.status) {
                case 0:
                    return {...state, downloading: true, filePath: null};
                case 1:
                    return {...state, downloading: false, filePath: action.filePath};
                default:
                    if (action.errorType === 'NoDataException') notification.warn({
                        message: 'Your Selection did not yield any results!',
                        description: 'Please try to adjust your search criteria.',
                        duration: 6
                    });
                    else error(action.error.message);
                    return {...state, downloading: false, filePath: null};
            }
        case TYPE_SELECT:
            return {...state, selectedType: action.lovType};
        case SEARCH_SET:
            return {...state, search: action.value};
        case ACTION_COUNT_INC:
            const changeCounter = state.changeCounter + 1;
            return {...state, changeCounter, counting: true};
        case ACTION_COUNT_RESET:
            return {...state, changeCounter: 0};
        default:
            return state
    }
}

/**
 * LOVS
 */
const fetchLovsRequest = country => ({type: FETCH_LOVS, country, status: 0});
const fetchLovsReceive = (country, lovs) => ({type: FETCH_LOVS, country, lovs, status: 1});
const fetchLovsError = error => ({type: FETCH_LOVS, error, status: -1});
export const loadLovs = (country) => (dispatch, getState) => {
    dispatch(fetchLovsRequest(country));
    return axios.get(`/api/lov/${country.key}`, getConfig(getState))
        .then(response => dispatch(fetchLovsReceive(country, response.data)))
        .catch(error => evalError(dispatch, fetchLovsError, error))
};


export const selectType = lovType => ({type: TYPE_SELECT, lovType});
export const setSearch = value => ({type: SEARCH_SET, value});

/**
 * COUNT
 */
const countRequest = selection => ({type: COUNT_GET, selection, status: 0});
const countReceive = count => ({type: COUNT_GET, count, status: 1});
const countError = error => ({type: COUNT_GET, error, status: -1});
export const getCount = () => (dispatch, getState) => {
    const state = getState();
    const simpleSelection = state.selection;
    const availableLovs = state.auth.availableLovs;
    const empty = isSelectionEmpty(simpleSelection, availableLovs);
    if (empty) {
        dispatch(countReceive({total: 0, inSelection: 0})); // when empty, set count to 0
        return; // do not continue to fetch counts
    }
    dispatch(countRequest());
    const selection = getSelection(null, simpleSelection);
    return axios
        .post(`/api/selection/count`, {...selection}, getConfig(getState))
        .then(response => {
            if (compareLovs(selection.lovs, getSelection(getState).lovs)) {
                return dispatch(countReceive(response.data));
            }
        })
        .catch(error => evalError(dispatch, countError, error))
};


/**
 * PREVIEW
 */
const previewRequest = () => ({type: PREVIEW_GET, status: 0});
const previewReceive = data => ({type: PREVIEW_GET, data, status: 1});
const previewError = error => ({type: PREVIEW_GET, error, status: -1});
export const getPreview = () => (dispatch, getState) => {
    dispatch(previewRequest());
    const selection = getSelection(getState);
    return axios
        .post(`/api/selection/preview`, {...selection}, getConfig(getState))
        .then(response => dispatch(previewReceive(response.data)))
        .catch(error => evalError(dispatch, previewError, error))
};


/**
 * DOWNLOAD
 */
const downloadRequest = () => ({type: DOWNLOAD, status: 0});
const downloadReceive = filePath => ({type: DOWNLOAD, status: 1, filePath});
const downloadError = (error, type) => ({type: DOWNLOAD, status: -1, error, errorType: type});
export const download = (printPack) => (dispatch, getState) => {
    dispatch(downloadRequest());
    const selection = getSelection(getState);
    const config = getConfig(getState);
    const {id, name, selected, sort} = getState().format;
    const template = selected.length > 0 ? {id, name, fields: selected, sort} : null;
    const downloadViaBox = getState().auth.downloadViaBox;
    return axios
        .post(
            `/api/selection/download` + (printPack ? '/mail' : ''),
            {...selection, template},
            { ...config, responseType: downloadViaBox ? 'text' : 'blob' },
        )
        .then(response => {
            let url;
            if (downloadViaBox) {
                url = downloadPath(response.data);
            } else {
                // create file link in browser's memory
                url = URL.createObjectURL(response.data);

                // create "a" HTML element with href to file & click
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', response.headers['content-disposition'].split('filename=')[1]); //or any other extension
                document.body.appendChild(link);
                link.click();
                // clean up "a" element & remove ObjectURL
                document.body.removeChild(link);
                URL.revokeObjectURL(url);
            }

            dispatch(downloadReceive(url))
        })
        .catch(error => {
            evalError(dispatch, downloadError, error)
        })
};

export const incrementActionCount = () => ({type: ACTION_COUNT_INC});
export const resetActionCount = () => ({type: ACTION_COUNT_RESET});