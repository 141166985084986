import AccountImported from './Account';
import EmailImported from './Email';
import FirstNameImported from './FirstName';
import LastNameImported from './LastName';
import PasswordImported from './Password';
import RoleImported from './Role';
import StatusImported from './Status';


export const Account = AccountImported;
export const Email = EmailImported;
export const FirstName = FirstNameImported;
export const LastName = LastNameImported;
export const Password = PasswordImported;
export const Role = RoleImported;
export const Status = StatusImported;

