import React from "react";
import PropTypes from "prop-types";
import {Dropdown, Icon, Menu, Table} from "antd";
import {byType} from "../../constants/LovTypes";
import Sorts from "../../constants/Sorts";
import {connect} from "react-redux";
import * as WizardActions from "../../reducers/wizard";
import * as SelectionActions from "../../reducers/selection";
import {bindActionCreators} from "redux";

const {Column} = Table;

const existsIn = (one, lookup) => one.toLowerCase().indexOf(lookup.toLowerCase()) !== -1;
const onFilter = (value, record) => existsIn(record.label + record.altLabel, value);

class LovTab extends React.PureComponent {

    type = byType(this.props.selectedType);

    state = {
        sort: this.type.sort,
        sortOrder: this.type.sort.defaultOrder
    };

    handleLovs = (selected, rows) => {
        const {addLovs, selectedType, removeLovs} = this.props;
        if (selected) addLovs(selectedType, rows);
        else removeLovs(selectedType, rows)
    };

    onChange = (pagination, filters, sorter) => this.setState({sortOrder: sorter.order});

    setSort = e => {
        const sort = e.item.props.value;
        this.setState({sort, sortOrder: sort.defaultOrder})
    };

    render() {
        const {search, loading, selection, selectedType, lovsByType} = this.props;
        const data = lovsByType[selectedType] || [];
        const selectedKeys = selection[selectedType].map(l => l.code);

        const {sort, sortOrder} = this.state;

        const rowSelection = {
            selectedRowKeys: selectedKeys,
            onSelect: (record, selected, selectedRows) => this.handleLovs(selected, [record]),
            onSelectAll: (selected, selectedRows, changeRows) => this.handleLovs(selected, changeRows)
        };

        const menu = (
            <Menu onClick={this.setSort} selectedKeys={[sort.title]}>
                {Object.keys(Sorts).map(key => {
                    const sort = Sorts[key];
                    return <Menu.Item key={sort.title} value={sort}>{sort.title}</Menu.Item>
                })}
            </Menu>
        );
        const dropdown = <Dropdown overlay={menu}><a style={{fontWeight: "normal"}}>Sort by {sort.title}
            <Icon type="down"/></a></Dropdown>;

        console.log('LovTab:render');

        return (
            <div>
                <Table
                    loading={loading}
                    bordered
                    rowSelection={rowSelection}
                    pagination={false}
                    size="default"
                    rowKey={r => r.code}
                    dataSource={data}
                    onChange={this.onChange}
                    scroll={{y: getTableHeight()}}
                >
                    <Column
                        sorter={sort.fun}
                        sortOrder={sortOrder}
                        filteredValue={[search]}
                        width={400}
                        onFilter={onFilter}
                        title={<span>Native Label {dropdown} </span>}
                        dataIndex="label"
                        key="label"
                    />
                    <Column title="English Translation" dataIndex="altLabel" key="altLabel"/>
                </Table>
            </div>
        );
    }
}

const getTableHeight = () => window.innerHeight * 0.765; // TODO make responsive

LovTab.propTypes = {
    search: PropTypes.string.isRequired,
    addLovs: PropTypes.func.isRequired,
    removeLovs: PropTypes.func.isRequired,
    selection: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    selectedType: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
    selection: state.selection,
    loading: state.wizard.loading,
    selectedType: state.wizard.selectedType,
    search: state.wizard.search,
    lovsByType: state.wizard.lovsByType,
    availableLovs: state.auth.availableLovs,
});

const mapDispatchToProps = dispatch => ({
    addLovs: bindActionCreators(SelectionActions, dispatch).addLovs,
    removeLovs: bindActionCreators(SelectionActions, dispatch).removeLovs,
    selectType: bindActionCreators(WizardActions, dispatch).selectType,
    setType: type => dispatch(SelectionActions.setType(type))
});

export default connect(mapStateToProps, mapDispatchToProps)(LovTab)
