import React, {Component} from "react";
import {Button, Card, Divider, Form, Input} from "antd"
import PropTypes from "prop-types"

const FormItem = Form.Item;

const formItemLayout = {
    labelCol: {span: 4},
    wrapperCol: {span: 18},
};

export default class TemplateSideBar extends Component {
    
    static propTypes = {
        onChangeName: PropTypes.func.isRequired,
        saveTemplate: PropTypes.func.isRequired,
        saveTemplateAs: PropTypes.func.isRequired,
        name: PropTypes.string.isRequired,
        templates: PropTypes.array.isRequired,
        loadTemplate: PropTypes.func.isRequired,
        loading: PropTypes.bool.isRequired,
        id: PropTypes.number.isRequired
    };
    
    render() {
        const {name, onChangeName, saveTemplate, saveTemplateAs, templates, loadTemplate, loading, id} = this.props;
        return <Card title="Template" loading={loading}>
            <Form>
                <FormItem label="Name" {...formItemLayout}>
                    <Input value={name} onChange={onChangeName}/>
                </FormItem>
            </Form>
            <Button disabled={id === 1 || id === 2} type="primary" icon="save" onClick={saveTemplate}>Save</Button>
            <Button type="primary" icon="save" onClick={saveTemplateAs}>Save As</Button>
            <Divider/>
            {
                templates.map(t => <p key={t.id}><a onClick={() => loadTemplate(t.id)}>{t.name} ({t.id})</a></p>)
            }
        </Card>
    }
    
}