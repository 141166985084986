import React, {Component} from "react";
import PropTypes from "prop-types"
import {Card, Col, Icon, Input, InputNumber, Popconfirm, Row, Spin, Tooltip} from "antd"

const {Meta} = Card;

export default class TemplateDisplay extends Component {
    
    static propTypes = {
        selectedFields: PropTypes.array.isRequired,
        loading: PropTypes.bool.isRequired,
        removeField: PropTypes.func.isRequired,
        changeSort: PropTypes.func.isRequired,
        changeHeader: PropTypes.func.isRequired
    };
    
    state = {
        sortPriority: null,
        header: ''
    };
    
    onDelete = index => {
        const {selectedFields, removeField} = this.props;
        const field = selectedFields[index];
        removeField(field);
    };
    
    onChangeSort = value => this.setState({sortPriority: value});
    
    onAcceptSort = (field) => {
        this.props.changeSort(field, this.state.sortPriority);
    };
    
    onChangeHeader = e => this.setState({header: e.target.value});
    
    onAcceptHeader = (field) => {
        const header = this.state.header.trim();
        if (header !== "") {
            this.props.changeHeader(field, header);
        }
    };
    
    render() {
        const {selectedFields, loading} = this.props;
        
        const getActions = (field, i) => [
            <Tooltip title="Rename">
                <Popconfirm title={<Input defaultValue={field.header} onChange={this.onChangeHeader}/>} onConfirm={() => this.onAcceptHeader(field)}>
                    <Icon type="edit"/>
                </Popconfirm>
            </Tooltip>,
            <Tooltip title="Remove">
                <Icon type="cross" onClick={() => this.onDelete(i)}/>
            </Tooltip>,
            <Tooltip title="Sort">
                <Popconfirm title={<InputNumber min={-1} max={100} onChange={this.onChangeSort}/>}
                            onConfirm={() => this.onAcceptSort(field)}>
                    {(field.sort  !== null && field.sort !== undefined) ? <span>{field.sort}</span> : <Icon type="swap"/>}
                </Popconfirm>
            </Tooltip>
        ];
        
        return <div>
            <Row>
                <Spin spinning={loading}/>
                {selectedFields.map((field, i) => {
                    return <Col span={2} key={i}>
                        <Card actions={getActions(field, i)}>
                            <Meta title={field.header}
                                  description={<p>{field.entity} - {field.type} - {field.name}</p>}/>
                        </Card>
                    </Col>
                })}
            </Row>
        </div>
    }
    
}