import React from "react";
import PropTypes from "prop-types"
import {Button, Checkbox, Form, Modal} from "antd";
import logo from "../../img/Logo-green-aPureBase-geen.png"
import {compose} from "recompose";

const DownloadDisclaimer = ({
    visible,
    download,
    toggleDownload,
    form,
    loading,
    printPack = false,
}) => {
    console.log('DownloadDisclaimer');
    const onOk = e => {
        form.validateFields(async (err, values) => {
            if (!err) {
                await download(printPack);
                if (visible) {
                    toggleDownload()
                }
            }
        })
    };

    const onCancel = e => toggleDownload();
    const afterClose = () => form.resetFields(['consent']);

    const footer = (
        <div>
            <Button onClick={onCancel}>Cancel</Button>
            <Button loading={loading} onClick={onOk} type="primary">OK</Button>
        </div>
    );
    return (
        <Modal
            title={printPack ? "Download & Request Print, Pack and Deployment" : "Download"}
            onOk={onOk}
            onCancel={onCancel}
            footer={footer}
            visible={visible}
            closable
            maskCloseable
            width={800}
            afterClose={afterClose}
        >
            <p>We urge you to remember that this is data on real persons and that these persons have rights as
                data subjects.
            </p>
            <p>Data extracted is deemed outdated 3 months after date of extraction and must then be deleted in
                all versions.
            </p>
            <br/>
            <p>Our native speaking data teams are ready to assist you – get in touch today and get the most out
                of the high quality data.
            </p>
            <p>Thank you and speak soon!</p>
            <br/>
            <img src={logo} alt="aPureBase" width={300} height={45}/>
            <br/>
            <br/>

            <Form layout="horizontal">
                <Form.Item>
                    {
                        form.getFieldDecorator('consent', {
                            rules: [{
                                required: true,
                                validator: (rule, value, callback) => value ? callback() : callback([new Error()]),
                                message: 'You have to accept the conditions before being able to download'
                            }],
                            valuePropName: 'checked',
                            initialValue: false
                        })(<Checkbox disabled={loading}>I accept the conditions and acknowledge that I am working with
                            personal data. </Checkbox>)
                    }
                </Form.Item>
            </Form>
        </Modal>
    );
};

DownloadDisclaimer.propTypes = {
    visible: PropTypes.bool.isRequired,
    download: PropTypes.func.isRequired,
    toggleDownload: PropTypes.func.isRequired,
    form: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    printPack: PropTypes.bool
};

export default compose(
    Form.create(),
)(DownloadDisclaimer);
