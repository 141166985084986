import React from "react";
import PropTypes from "prop-types";
import {compose} from 'recompose';
import {Alert, Button, Checkbox, Form, Icon, Input, Spin} from "antd";
import {bindActionCreators} from "redux";
import {NavLink, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import * as AuthActions from "../reducers/auth"
import "../css/App.css"
import {WithFooter} from "./WithFooter";
import {checkBrowser} from "../util/Utility";
import LocationVerifier from "./LocationVerifier";

const FormItem = Form.Item;

let tmpEmailFix = null;

class LoginForm extends React.Component {

    state = {
        isIE: false,
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const {email, password, remember} = values;
                tmpEmailFix = email;
                this.props
                    .login(email, password, remember)
                    .then(() => this.props.history.push("/"));
            } else {
                console.error('Failed', err);
            }
        });
    };

    handleVerify = (e, form) => {
        e.preventDefault();
        const {verifyLocation} = this.props;
        form.validateFields((err, values) => {
            if (!err) {
                verifyLocation(values.code, tmpEmailFix)
                    .then(() => this.props.history.push("/"));;
            }
        })
    };

    componentDidMount() {
        const search = this.props.location.search.substring(1).split("=");
        const activate = search[0] === "activate" ? search[1] : null;
        if (activate) {
            this.props.activateUser(activate);
        }
        const isIE = checkBrowser();
        if (isIE) {
            this.setState({isIE});
        }
    }


    render() {
        const {form, sendingRequest, activating, showLocationVerifier, verifying, verifyLocation} = this.props;
        const {getFieldDecorator} = form;
        return <WithFooter>
            <div className="login-header">
                <p style={{fontSize: 16}}>Welcome to the aPureBase Mailing Tool!</p>
                <p>We make mailing lists easier than ever.</p>
            </div>
            <Spin spinning={activating}>
                <Form onSubmit={this.handleSubmit} className="login-form">
                    <FormItem>
                        {getFieldDecorator('email', {
                            rules: [{required: true, message: 'Please input your email!'}],
                        })(
                            <Input
                                prefix={<Icon type="mail" style={{fontSize: 13}}/>}
                                placeholder="Email"
                                disabled={showLocationVerifier}
                            />
                        )}
                    </FormItem>
                    <FormItem>
                        {getFieldDecorator('password', {
                            rules: [{required: true, message: 'Please input your Password!'}],
                        })(
                            <Input
                                prefix={<Icon type="lock" style={{fontSize: 13}}/>}
                                type="password"
                                placeholder="Password"
                                disabled={showLocationVerifier}
                            />
                        )}
                    </FormItem>
                    <FormItem>
                        {getFieldDecorator('remember', {
                            valuePropName: 'checked',
                            initialValue: true,
                        })(
                            <Checkbox>Remember me</Checkbox>
                        )}
                        <NavLink className="login-form-forgot" to="/login/help">Forgot password</NavLink>
                        <Button type="primary" htmlType="submit" className="login-form-button"
                                loading={sendingRequest}>
                            Log in
                        </Button>

                    </FormItem>
                    {this.state.isIE ? <Alert
                        type="info"
                        showIcon
                        message="We noticed you are using Internet Explorer"
                        description="We recommend to use Firefox, Edge, or Chrome for an optimal experience."
                    /> : null}
                </Form>
            </Spin>
            <LocationVerifier
                visible={showLocationVerifier}
                verifying={verifying}
                verifyLocation={verifyLocation}
                handleVerify={this.handleVerify}
            />
        </WithFooter>;
    }
}

LoginForm.propTypes = {
    login: PropTypes.func.isRequired,
    sendingRequest: PropTypes.bool.isRequired,
    activateUser: PropTypes.func.isRequired,
    activating: PropTypes.bool.isRequired,
    showLocationVerifier: PropTypes.bool.isRequired,
    verifying: PropTypes.bool.isRequired,
    verifyLocation: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    sendingRequest: state.auth.currentlySending,
    activating: state.auth.activating,
    verifying: state.auth.verifying,
    showLocationVerifier: state.auth.showLocationVerifier
});

const mapDispatchToProps = dispatch => ({
    login: bindActionCreators(AuthActions, dispatch).login,
    activateUser: bindActionCreators(AuthActions, dispatch).activateUser,
    verifyLocation: bindActionCreators(AuthActions, dispatch).verifyLocation
});

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    Form.create(),
)(LoginForm);
