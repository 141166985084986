import React from "react"
import PropTypes from "prop-types"
import {NavLink} from "react-router-dom";
import {connect} from "react-redux";
import {WithFooter} from "../authentication/WithFooter";

const NotFound = ({loggedIn}) => <WithFooter>
    <div className="login-header">
        <p style={{fontSize: 16}}>Page not found - 404</p>
        <p>Sorry, we cannot find what you are looking for.</p>
        {loggedIn
            ? <p>Go back to the <NavLink to="/selection">Selection</NavLink> page.</p>
            : <p>Go back to <NavLink to="/login">Login</NavLink>.</p>}
    </div>
</WithFooter>;

NotFound.propTypes = {
  loggedIn: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
    loggedIn: state.auth.loggedIn
});

export default connect(mapStateToProps)(NotFound)
