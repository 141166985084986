import * as React from "react";
import PropTypes from "prop-types"
import {Checkbox, Collapse} from "antd"
import {connect} from "react-redux";
import * as SelectionActions from "../../reducers/selection";

const Panel = Collapse.Panel;

class OptionPane extends React.PureComponent {
    
    onTypeChange = e => {
        const checked = e.target.checked;
        const type = checked ? 'site' : 'person';
        this.props.setType(type);
    };
    
    render() {
        const {type} = this.props;
        return <Collapse bordered={false} defaultActiveKey={['1']}>
            <Panel header="Options" key="1">
                <Checkbox checked={type === 'site'} onChange={this.onTypeChange}>Select Sites Only</Checkbox>
            </Panel>
        </Collapse>
    }
    
}

OptionPane.propTypes = {
    type: PropTypes.string.isRequired,
    setType: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    type: state.selection.type
});

const mapDispatchToProps = dispatch => ({
    setType: type => dispatch(SelectionActions.setType(type))
});

export default connect(mapStateToProps, mapDispatchToProps)(OptionPane)