import React from "react";
import PropTypes from "prop-types"
import {Form, Tooltip, Input, Switch, Icon} from "antd"



const SelectionSettings = ({
    formItemLayout,
    procedure,
    table,
    globalPattern,
    getFieldDecorator,
    code,
    onGlobalChange,
}) => {
    const usingGlobal = procedure === globalPattern.procedure && table === globalPattern.table;

    const Label = ({ text, tooltip }) => (
      <span>
        <span>{text}&nbsp;</span>
        <Tooltip title={tooltip}>
            <Icon type="question-circle-o" />
        </Tooltip>
      </span>
    );

    return <div>
        <Form.Item {...formItemLayout} label={<Label text="Code" tooltip="Use {country} as the country code placeholder" />}>
            {getFieldDecorator('code', {
                rules: [{message: 'Please enter a valid code!', whitespace: true}],
                initialValue: code,
            })(
              <Input disabled={false} placeholder="Enter Account Code"/>
            )}
        </Form.Item>

        <Form.Item {...formItemLayout} label={<Label text="Procedure" tooltip="Use {country} as the country code placeholder" />}>
            {getFieldDecorator('procedure', {
                rules: [{message: 'Please enter a valid procedure pattern!', whitespace: true}],
                initialValue: procedure
            })(<Input
              disabled={false}
              placeholder="Enter the procedure name pattern"
            />)}
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label={<Label
            text="Table"
            tooltip="Use {country} as the country code placeholder, and {type} as the type placeholder (person / site)"
          />}
        >
            {getFieldDecorator('table', {
                rules: [{message: 'Please enter a valid table pattern!', whitespace: true}],
                initialValue: table
            })(
              <Input disabled={false} placeholder="Enter the table name pattern"/>
            )}
        </Form.Item>

        <Form.Item
            {...formItemLayout}
            label="Use Global Pattern"
        >
            {getFieldDecorator('globalPattern', {
                initialValue: usingGlobal,
                valuePropName: 'checked'
            })(<Switch onChange={onGlobalChange} />)}
        </Form.Item>
    </div>
};


SelectionSettings.propTypes = {
    procedure: PropTypes.string,
    table: PropTypes.string,
    code: PropTypes.string,
    globalPattern: PropTypes.object.isRequired,
    formItemLayout: PropTypes.object.isRequired,
    getFieldDecorator: PropTypes.func.isRequired
};

export default SelectionSettings
