import React from "react"
import PropTypes from "prop-types"
import {Card, Icon, List} from "antd"
import {Country} from "../constants/Countries";
import {NavLink} from "react-router-dom";

const ArchiveGrid = ({archive, loadingArchive, filter}) => {
    return <Card title={`Recently updated selections by: ${filter.name || 'All'}`} type="inner"
                 style={{overflowY: 'auto', height: '50vh'}}>
        <List
            grid={{gutter: 16, xs: 1, sm: 1, md: 1, lg: 1, xl: 2, xxl: 2}}
            dataSource={archive.filter(sel => !filter.email || sel.email === filter.email)}
            loading={loadingArchive}
            renderItem={selection => <List.Item>
                <NavLink to={`/selection/${selection.id}`}>
                    <Card hoverable={true} type="inner" bodyStyle={{lineHeight: 1}}>
                        <p className="bold">{Country.getFlag(selection.country)} <Icon
                            type={selection.type === 'site' ? 'home' : 'user'}/> {selection.title}</p>
                        <p>Owner: {selection.email}</p>
                        <p>{selection.ut.toLocaleString()}</p>
                    </Card>
                </NavLink>
            </List.Item>}
        />
    </Card>
};

ArchiveGrid.propTypes = {
    archive: PropTypes.array.isRequired,
    loadingArchive: PropTypes.bool.isRequired,
    filter: PropTypes.object.isRequired
};

export default ArchiveGrid