import React from "react";
import {render} from "react-dom";
import App from "./App";
import thunkMiddleware from "redux-thunk";
import {applyMiddleware, createStore} from "redux";
import reducer from "./reducers";
import {ConfigProvider} from "antd";
import enUS from "antd/lib/locale-provider/en_US";
import "./css/App.css";
import "./css/main.less";
import "antd/dist/antd.less";
import {library} from '@fortawesome/fontawesome-svg-core'
import {
    faAmbulance,
    faBold,
    faBriefcase,
    faCode,
    faFilter,
    faGlobe,
    faGraduationCap,
    faHeading,
    faHospital,
    faImage,
    faItalic,
    faLink,
    faListOl,
    faListUl,
    faQuoteRight,
    faStethoscope,
    faStrikethrough,
    faTasks,
    faUserMd
} from '@fortawesome/free-solid-svg-icons'

library.add(faAmbulance, faBriefcase, faUserMd, faGraduationCap, faGlobe, faHospital, faStethoscope, faFilter,
    faHeading, faLink, faCode, faImage, faStrikethrough, faListOl, faListUl, faBold, faItalic, faQuoteRight, faTasks);


let store = createStore(
    reducer, applyMiddleware(
        thunkMiddleware
    )
);


render(
< ConfigProvider
locale = {enUS} >
    < App
store = {store}
/>
< /ConfigProvider>
    ,
    document.getElementById('root')
)
;
