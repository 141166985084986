import React from 'react';
import {Form, Icon, Input} from 'antd';
import {formItemLayout} from "../UserEditor";

export default ({
                    initialValue,
                    form: {getFieldDecorator},
                    disabled = false,
                }) => (
    <Form.Item label="Password" {...formItemLayout} >
        {getFieldDecorator('password', {
            rules: [
                {required: true, message: 'Please enter a password!', whitespace: true},
                {min: 4, message: 'The password should be at least 4 characters long'}
            ],
            initialValue
        })(
            <Input autoComplete="new-password" disabled={disabled}
                   prefix={<Icon type="lock" style={{fontSize: 13}}/>}
                   type="password"
            />
        )}
    </Form.Item>
);
