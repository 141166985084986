import * as React from "react";
import PropTypes from "prop-types"
import {Table, Badge} from "antd"

class LoginAttemptTable extends React.Component {
    
    render() {
        const {logins, getAvatar} = this.props;
        const loginColumns = [
            {title: 'User', key: 'user', dataIndex: 'userId', render: (userId, record, i) => getAvatar(userId)},
            {title: 'IP', key: 'ip', dataIndex: 'ip'},
            {
                title: 'Timestamp',
                key: 'timestamp',
                dataIndex: 'timestamp',
                render: (text, record, index) => text.toLocaleString()
            },
            {
                title: 'Success',
                key: 'success',
                dataIndex: 'success',
                render: (success, record, i) => <Badge status={success ? "success" : "error"}/>
            }
        ];
        return <Table title={currentPageData => <span>Login Attempts</span>} columns={loginColumns}
                      dataSource={logins} rowKey="timestamp"/>
    }
    
}

LoginAttemptTable.propTypes = {
    logins: PropTypes.array.isRequired,
    getAvatar: PropTypes.func.isRequired
};

export default LoginAttemptTable