/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import {AutoComplete, Button, Form, Modal, Select} from "antd";
import {compose} from 'recompose';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {LovOptions} from "../../constants/LovTypes";
import {getCountryOptions} from "../../constants/Countries";
import {Account, Email, FirstName, LastName, Password, Role, Status} from './components';
import * as SettingsActions from "../../reducers/settings";
import {isApureBaseEmail} from "../../util/Utility";

const Option = AutoComplete.Option;

export const formItemLayout = {
    labelCol: {span: 8},
    wrapperCol: {span: 14},
};

const getLovOptions = lovs => LovOptions.filter(option => lovs.includes(option.key));

const UserManagement = ({
    form,
    editUserId,
    registerUser,
    visible,
    close,
    accounts,
    showAccountEditor,
    userId,
    users,
    userEditing,
    isApureBase,
}) => {
    const [availableCountries, setAvailableCountries] = useState([]);
    const [availableLovs, setAvailableLovs] = useState([]);

    const onChangeAccount = (accountId, userToEdit) => {
        const {isFieldTouched, setFieldsValue} = form;
        const acc = accounts.find(acc => acc.id.toString() === accountId);
        const countries = acc ? acc.countries : [];
        const lovs = acc ? acc.lovs : [];
        const bricks = acc ? Object.keys(acc.bricks).reduce((array, key) => {
            const bricks = acc.bricks[key].filter(brick => !array.includes(brick));
            return array.concat(bricks);
        }, []) : [];
        const totalLovs = [...lovs, ...bricks];
        if (!isFieldTouched('countries') || !acc) {
            // when the field is not yet touched, suggest all Account Countries by default
            setFieldsValue({'countries': userToEdit ? userToEdit.countries : countries})
        }
        if (!isFieldTouched('lovs') || !acc) {
            // when the field is not yet touched, suggest all Account LOVs by default
            setFieldsValue({'lovs': userToEdit ? userToEdit.lovs : totalLovs})
        }
        setAvailableCountries(getCountryOptions(countries));
        setAvailableLovs(getLovOptions(totalLovs));
    };

    useEffect(() => {
        // The action has changed between Create and Edit, clear data
        handleReset();
        if (editUserId) {
            // when editing, find the user and load its data
            const user = users.find(u => u.id === editUserId);
            onChangeAccount(user.accountId.toString(), user)
        } else {
            // when creating, clear choices initially
            setAvailableLovs([]);
            setAvailableCountries([]);
        }
    }, [editUserId]);

    const handleReset = () => form.resetFields();

    const handleSubmit = e => {
        e.preventDefault();
        form.validateFields(async (err, values) => {
            if (!err) {
                await registerUser({...values, id: editUserId || -1}, editUserId);
                if (visible) close();
                handleReset();
            }
        });
    };


    const editingSelf = userId === editUserId;

    let editing = false;
    let email = null;
    let role = "";
    let accountId = null;
    let password = "placeholder";
    let locked = true;
    let firstname = null;
    let lastname = null;
    let disabled = false;

    // EDIT
    if (editUserId) {
        editing = true;
        const user = users.find(u => u.id === editUserId);
        email = user.email;
        role = user.role;
        locked = user.locked;
        accountId = user.accountId.toString();
        firstname = user.firstname;
        lastname = user.lastname;
        disabled = isApureBaseEmail(user.email);
    }

    const addAccountOption = (
        <Option disabled className="centered-item" key="0" value="0">
            <Button onClick={showAccountEditor} type="dashed">Create New Account</Button>
        </Option>
    );
    const options = accounts.map(acc => <Option key={acc.id} value={acc.id.toString()}>{acc.name}</Option>);
    const finalOptions = (isApureBase ? [addAccountOption] : []).concat(options);


    return (
        <Modal
            title="Edit User"
            visible={visible}
            onOk={handleSubmit}
            confirmLoading={userEditing}
            onCancel={close}
        >
            <Form
                autoComplete="off"
                layout="horizontal"
                onSubmit={handleSubmit}
            >
                <Email initialValue={email} form={form} disabled={disabled} />
                <FirstName initialValue={firstname} form={form} disabled={disabled} />
                <LastName initialValue={lastname} form={form} disabled={disabled}/>
                <Password initialValue={password} form={form} disabled={true}/>
                <Account
                    initialValue={accountId}
                    form={form}
                    options={finalOptions}
                    disabled={editing}
                    onChange={onChangeAccount}
                />
                <Role initialValue={role} form={form} disabled={disabled}/>
                <Status initialValue={locked} form={form} disabled={!editing || editingSelf}/>

                <Form.Item label="Countries" {...formItemLayout}>
                    {
                        form.getFieldDecorator('countries', {
                            rules: [{required: true, message: "Please specify at least one country"}]
                        })(
                            <Select
                                mode="multiple"
                                tokenSeparators={[',']}
                            >
                                {availableCountries}
                            </Select>
                        )
                    }
                </Form.Item>

                <Form.Item label="LOVs" {...formItemLayout}>
                    {
                        form.getFieldDecorator('lovs', {
                            rules: [{required: true, message: "Please specify at least one type of LOV"}]
                        })(
                            <Select mode="multiple" tokenSeparators={[',']}>{availableLovs}</Select>
                        )
                    }
                </Form.Item>
            </Form>
        </Modal>
    );
};

/*
addonAfter={editing ?
                                   <Popconfirm title="Are you sure you want to reset the password for this user?">
                                       <a>Reset Password</a></Popconfirm> : null}
 */

UserManagement.propTypes = {
    registerUser: PropTypes.func.isRequired,
    accounts: PropTypes.array.isRequired,
    showAccountEditor: PropTypes.func.isRequired,
    visible: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    userId: PropTypes.number.isRequired,
    editUserId: PropTypes.number,
    users: PropTypes.array.isRequired,
    userEditing: PropTypes.bool.isRequired,
    isApureBase: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
    accounts: state.settings.accounts,
    users: state.settings.users,
    userEditing: state.settings.userEditing,
    userId: state.auth.id,
    isApureBase: state.auth.isApureBase,
});

const mapDispatchToProps = dispatch => ({
    registerUser: bindActionCreators(SettingsActions, dispatch).registerUser,
    getAccounts: bindActionCreators(SettingsActions, dispatch).getAccounts,
});

export default compose(
    Form.create(),
    connect(mapStateToProps, mapDispatchToProps),
)(UserManagement);
