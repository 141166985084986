import React from "react";
import PropTypes from "prop-types";
import {Icon, Layout, Menu} from "antd";
import {connect} from "react-redux";
import {NavLink} from "react-router-dom";
import {withRouter} from "react-router";
import logo from "./img/Logo-green-aPureBase-geen.png";

const {Header, Content} = Layout;
const SubMenu = Menu.SubMenu;
const headerHeight = 50;

const AppLayout = ({
  location,
  loggedIn,
  email,
  children,
  isApureBase,
  storeSelectionId,
  role,
}) => {

  const path = location.pathname.slice(1).split('/')[0];
  const active = path === '' ? 'home' : path;

  //if (!loggedIn) return children;
  return (
    <Layout style={{minHeight: '100vh'}}>
      <Header style={{position: 'fixed', zIndex: 1, width: '100%', paddingLeft: 10}}>
        <div className="logo">
          <NavLink to='/'>
            <img className="logo" alt="aPureBase" src={logo}/>
          </NavLink>
        </div>
        <Menu theme="dark" mode="horizontal" selectedKeys={[active]} style={{lineHeight: `${headerHeight}px`}}>
          {
            loggedIn ? [
              <Menu.Item key="home"><NavLink to="/">Home</NavLink></Menu.Item>,
              <Menu.Item key="archive"><NavLink to="/archive">Archive</NavLink></Menu.Item>,
              <Menu.Item key="selection"><NavLink to={{
                pathname: storeSelectionId ? `/selection/${storeSelectionId}` : "/selection",
                state   : {fromNav: true},
              }}>Selection</NavLink></Menu.Item>,
            ] : []
          }

          {(loggedIn && isApureBase && role === 'Admin') ?
            <Menu.Item key="/templates"><NavLink to="/templates">Templates</NavLink></Menu.Item> : null
          }
          {
            (loggedIn && isApureBase) ?
              <Menu.Item key="/literal"><NavLink to="/literal">Literal</NavLink></Menu.Item> : null
          }

          {
            loggedIn
              ? <SubMenu className="floatRight"
                         title={<span>Logged in as: <i>{email || ""}</i></span>}>
                <Menu.Item key="settings"><NavLink to="/settings"><Icon
                  type="setting"/>Settings</NavLink></Menu.Item>
                <Menu.Item key="logout"><NavLink to="/logout"><Icon
                  type="logout"/>Logout</NavLink></Menu.Item>
              </SubMenu>
              : null
          }
        </Menu>
      </Header>
      <Layout>
        <Content style={{marginTop: headerHeight}}>
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};

AppLayout.propTypes = {
  email           : PropTypes.string,
  role            : PropTypes.string,
  loggedIn        : PropTypes.bool.isRequired,
  isApureBase     : PropTypes.bool.isRequired,
  storeSelectionId: PropTypes.number,
};

const mapStateToProps = state => ({
  email           : state.auth.email,
  role            : state.auth.role,
  loggedIn        : state.auth.loggedIn,
  isApureBase     : state.auth.isApureBase,
  storeSelectionId: state.selection.id,
});

export default withRouter(connect(mapStateToProps, null)(AppLayout))
