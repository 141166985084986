const ascend = "ascend";
const descend = "descend";

export const text = (a, b) => {
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
};

export default {
    sortAlphabet: {
        title: "Alphabet",
        fun: (a, b) => text(a.label, b.label),
        defaultOrder: ascend
    },

    sortByCount: {
        title: "Count",
        fun  : (a, b) => a.count - b.count,
        defaultOrder: descend
    }

}