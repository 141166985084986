import React from "react"
import {AutoComplete} from "antd"

export class Country {
    constructor(key, name, defaultPersType) {
        this.key = key;
        this.name = name;
        this.defaultPersType = defaultPersType;
    }

    getFlag = () => Country.getFlag(this.key);

    static getFlag = countryCode => <span key={countryCode} className={`flag flag-${countryCode.toLowerCase()}`}/>
}

const Countries = {
    DK: new Country("DK", "Denmark", {code: "DK28734786--MEDI-CENT", label: "Læge", altLabel: "Doctor", type: 'PERS_TYPE'}),
    FI: new Country("FI", 'Finland', {code: "FIINDTCM", label: "Lääkäri", altLabel: "Doctor", type: 'PERS_TYPE'}),
    NO: new Country('NO', 'Norway', {code: "NOINDTCM", label: "Lege", altLabel: "Doctor", type: 'PERS_TYPE'}),
    SE: new Country('SE', 'Sweden', {code: "SEHSIPT015", label: "Läkare", altLabel: "Doctor", type: 'PERS_TYPE'}),
    NL: new Country('NL', 'Netherlands', {code: "NLArts", label: "Arts", altLabel: "Physician", type: 'PERS_TYPE'}),
    LV: new Country('LV', 'Latvia ', {code: 'LVPT001', label: 'Ārsts', altLabel: 'Doctor', type: 'PERS_TYPE'}),
    IT: new Country('IT', 'Italy ', {code: 'IT14', label: 'Medico', altLabel: 'Doctor', type: 'PERS_TYPE'}),
};

export default Countries;

export const CountryOptions = Object.keys(Countries).map(key => {
    const {name} = Countries[key];
    return <AutoComplete.Option key={key}>{Country.getFlag(key)} {name}</AutoComplete.Option>
});

export const getCountryOptions = countries => CountryOptions.filter(option => countries.includes(option.key));
