import axios from "axios";
import {notification} from "antd";
import {error, success} from "../constants/Messaging";
import {evalError, getConfig, getSelection as fetchSelection} from "./utility";
import {loadLovs, getCount} from "./wizard";
import {setCountry} from "./selection"
import {DASHBOARD_FETCH} from "./dashboard";

const initialState = {
    loadCompleted: false,
    saving: false,
    loading: false,
    gettingSelection: false,
    deleting: false,
    store: [] // loaded selections
};

export const SELECTION_SAVE = "SELECTION_SAVE";
export const SELECTION_GET = "SELECTION_GET";
export const SELECTION_DELETE = "SELECTION_DELETE";
const ARCHIVE_LOAD = "ARCHIVE_LOAD";

export default (state = initialState, action) => {
    switch (action.type) {
        case SELECTION_SAVE:
            switch (action.status) {
                case 0:
                    return {...state, saving: true};
                case 1:
                    notification.success({
                        message: "Your Selection has been saved!",
                        description: "It can be found again in the Archive or by entering the current url in your browser.",
                        duration: 5
                    });

                    return {...state, saving: false};
                default:
                    error(action.error);
                    return {...state, saving: false};
            }
        case ARCHIVE_LOAD:
            switch (action.status) {
                case 0:
                    return {...state, loading: true};
                case 1:
                    const store = action.store.map(sel => ({...sel, ut: new Date(sel.ut)}));
                    return {...state, loading: false, store};
                default:
                    error(action.error);
                    return {...state, loading: false};
            }
        case SELECTION_GET:
            switch (action.status) {
                case 0:
                    return {...state, gettingSelection: true, loadCompleted: false};
                case 1:
                    return {...state, gettingSelection: false, loadCompleted: true};
                default:
                    error(action.error);
                    return {...state, gettingSelection: false, loadCompleted: false};
            }
        case SELECTION_DELETE:
            switch (action.status) {
                case 0:
                    return {...state, deleting: true};
                case 1:
                    success("The Selection has been deleted");
                    const store = state.store.filter(sel => sel.id === action.id);
                    return {...state, deleting: false, store};
                default:
                    error(action.error);
                    return {...state, deleting: false};
            }
        case DASHBOARD_FETCH:
            if (action.status === 1) {
                return {...state, store: action.archive}
            } else return state;
        default:
            return state;
    }
}

/**
 Save Selection
 */
const saveSelectionRequest = selection => ({type: SELECTION_SAVE, status: 0, selection});
const saveSelectionReceive = selection => ({type: SELECTION_SAVE, status: 1, selection});
const saveSelectionError = error => ({type: SELECTION_SAVE, status: -1, error});
export const save = (history, location, selection) => (dispatch, getState) => {
    const toSave = fetchSelection(getState, selection);
    dispatch(saveSelectionRequest(toSave));
    return axios.post(`/api/archive`, toSave, getConfig(getState))
    // TODO: Instead of making an extra request, we should get the saved selection back from the backend
        .then(({data: id}) => axios.get(`/api/archive/${id}`, getConfig(getState)))
        .then(({data: selection}) => {
            dispatch(saveSelectionReceive(selection));
            history.push(`/selection/${selection.id}`)
        })
        .catch(err => evalError(dispatch, saveSelectionError, err));
};

/**
 Load Archive
 */
const loadArchiveRequest = () => ({type: ARCHIVE_LOAD, status: 0});
const loadArchiveReceive = store => ({type: ARCHIVE_LOAD, status: 1, store});
const loadArchiveError = error => ({type: ARCHIVE_LOAD, status: -1, error});
export const loadArchive = () => (dispatch, getState) => {
    dispatch(loadArchiveRequest);
    return axios.get(`/api/archive`, getConfig(getState))
        .then(res => dispatch(loadArchiveReceive(res.data)))
        .catch(err => evalError(dispatch, loadArchiveError, err))
};

/**
 Get Selection
 */
const getSelectionReq = id => ({type: SELECTION_GET, status: 0, id});
const getSelectionRec = selection => ({type: SELECTION_GET, status: 1, selection});
const getSelectionErr = error => ({type: SELECTION_GET, status: -1, error});
export const getSelection = id => (dispatch, getState) => {
    dispatch(getSelectionReq(id));
    return axios.get(`/api/archive/${id}`, getConfig(getState))
        .then(res => {
            const oldCountry = getState().selection.country;
            dispatch(getSelectionRec(res.data));
            const newCountry = getState().selection.country;
            dispatch(setCountry(newCountry, false, false));
            if (oldCountry !== newCountry) {
                dispatch(loadLovs(newCountry))
            }
            dispatch(getCount())
        })
        .catch(err => evalError(dispatch, getSelectionErr, err))
};

/**
 Delete Selection
 */
const deleteSelectionReq = id => ({type: SELECTION_DELETE, status: 0, id});
const deleteSelectionRec = id => ({type: SELECTION_DELETE, status: 1, id});
const deleteSelectionErr = error => ({type: SELECTION_DELETE, status: -1, error});
export const deleteSelection = id => (dispatch, getState) => {
    dispatch(deleteSelectionReq(id));
    return axios.delete(`/api/archive/${id}`, getConfig(getState))
        .then(res => {
            dispatch(deleteSelectionRec(id));
            dispatch(loadArchive())
        })
        .catch(err => evalError(dispatch, deleteSelectionErr, err))
};

