import axios from "axios"
import {evalError, getConfig} from "./utility";
import {getFieldKey} from "../util/Utility";
import {notification} from "antd"

const initialState = {
    fields: [],
    templates: [],
    id: null,
    name: 'New Template',
    selected: [],
    sort: [],
    fetching: false,
    fetchingTemplate: false
};

const FIELDS_FETCH = "PROPS_FETCH";
const FIELD_ADD = "FIELD_ADD";
const FIELD_REMOVE = "FIELD_REMOVE";
const TEMPLATE_FETCH = "TEMPLATE_FETCH";
const TEMPLATE_SAVE = "TEMPLATE_SAVE";
const OVERVIEW_FETCH = "OVERVIEW_FETCH";
const NAME_CHANGE = "NAME_CHANGE";
const FIELD_SORT = "FIELD_SORT";
const FIELD_HEADER = "FIELD_HEADER";

export default (state = initialState, action) => {
    switch (action.type) {
        case FIELDS_FETCH:
            switch (action.status) {
                case 0:
                    return {...state, fetching: true};
                case 1:
                    const {fields} = action;
                    return {...state, fetching: false, fields};
                default:
                    return {...state, fetching: false};
            }
        case TEMPLATE_FETCH:
            switch (action.status) {
                case 0:
                    return {...state, fetchingTemplate: true};
                case 1:
                    const {id, name, fields} = action.template;
                    return {...state, id, name, selected: fields, fetchingTemplate: false};
                default:
                    return {...state, fetchingTemplate: false};
            }
        case OVERVIEW_FETCH:
            switch (action.status) {
                case 0:
                    return {...state};
                case 1:
                    const {templates} = action;
                    return {...state, templates};
                default:
                    return {...state};
            }
        case TEMPLATE_SAVE:
            switch (action.status) {
                case 0:
                    return state;
                case 1:
                    notification.success({
                        message: "Your Template has been saved!",
                        description: "You can use it in your current Selection, set it as default, or share it with other people. ",
                        duration: 5
                    });
                    return {...state};
                default:
                    notification.error({
                        message: "An error occurred whilst saving your Template",
                        description: "Please try again later or contact support for help",
                        duration: 5
                    });
                    return {...state};
            }
        case NAME_CHANGE:
            return {...state, name: action.name};
        case FIELD_ADD:
            const selected = [...state.selected, action.field];
            return {...state, selected};
        case FIELD_REMOVE:
            const keyToRemove = getFieldKey(action.field);
            const selectedWithoutField = state.selected.filter(field => getFieldKey(field) !== keyToRemove);
            return {...state, selected: selectedWithoutField};
        case FIELD_SORT:
            const {field, priority} = action;
            const sort = priority === -1 ? null : priority;
            const sortedFields = state.selected.map(updateField(field, {sort}));
            return {...state, selected: sortedFields};
        case FIELD_HEADER:
            return {...state, selected: state.selected.map(updateField(action.field, {header: action.header}))};
        default:
            return state;
    }
}

const updateField = (field, update) => (el) => el === field ? {...field, ...update} : el;

/**
 * Fetch Props
 */
const fetchFieldsReq = () => ({type: FIELDS_FETCH, status: 0});
const fetchFieldsRec = fields => ({type: FIELDS_FETCH, status: 1, fields});
const fetchFieldsErr = error => ({type: FIELDS_FETCH, status: -1, error});
export const fetchProps = () => (dispatch, getState) => {
    dispatch(fetchFieldsReq());
    return axios.get('/api/templates/fields', getConfig(getState))
        .then(res => dispatch(fetchFieldsRec(res.data)))
        .catch(err => evalError(dispatch, fetchFieldsErr, err))
};

/**
 * Fetch a Template
 */
const fetchTemplateReq = () => ({type: TEMPLATE_FETCH, status: 0});
const fetchTemplateRec = template => ({type: TEMPLATE_FETCH, status: 1, template});
const fetchTemplateErr = error => ({type: TEMPLATE_FETCH, status: -1, error});
export const fetchTemplate = (id) => (dispatch, getState) => {
    const fetching = getState().format.fetchingTemplate;
    if (fetching) return;
    dispatch(fetchTemplateReq());
    return axios.get(`/api/templates/${id}`, getConfig(getState))
        .then(res => dispatch(fetchTemplateRec(res.data)))
        .catch(err => evalError(dispatch, fetchTemplateErr, err))
};

/**
 * Save a Template
 */
const saveTemplateReq = () => ({type: TEMPLATE_SAVE, status: 0});
const saveTemplateRec = template => ({type: TEMPLATE_SAVE, status: 1, template});
const saveTemplateErr = error => ({type: TEMPLATE_FETCH, status: -1, error});
export const saveTemplate = (overwrite) => (dispatch, getState) => {
    dispatch(saveTemplateReq());
    const {id, name, selected, sort} = getState().format;
    const data = {id: overwrite ? id : null, name, fields: selected, sort};
    return axios
        .post('/api/templates', data, getConfig(getState))
        .then(res => dispatch(saveTemplateRec(res.data)))
        .catch(error => evalError(dispatch, saveTemplateErr, error))
};

/**
 * Fetch an overview of Templates without their fields
 */
const fetchOverviewReq = () => ({type: OVERVIEW_FETCH, status: 0});
const fetchOverviewRec = templates => ({type: OVERVIEW_FETCH, status: 1, templates});
const fetchOverviewErr = error => ({type: OVERVIEW_FETCH, status: -1, error});
export const fetchOverview = () => (dispatch, getState) => {
    dispatch(fetchOverviewReq());
    return axios.get('/api/templates/overview', getConfig(getState))
        .then(res => dispatch(fetchOverviewRec(res.data)))
        .catch(error => evalError(dispatch, fetchOverviewErr, error))
};

export const addField = field => ({type: FIELD_ADD, field});
export const removeField = field => ({type: FIELD_REMOVE, field});
export const changeName = name => ({type: NAME_CHANGE, name});
export const changeSort = (field, priority) => ({type: FIELD_SORT, field, priority});
export const changeHeader = (field, header) => ({type: FIELD_HEADER, field, header});