import React from "react"
import {AutoComplete} from "antd"
import Sorts from "./Sorts";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

class LovType {
    constructor(title, type, icon, sort, tooltip, representation = 'Table') {
        this.title = title;
        this.type = type;
        this.icon = icon;
        this.sort = sort;
        this.tooltip = tooltip;
        this.representation = representation;
    }
    
    getImage = () => <FontAwesomeIcon key={this.type} icon={this.icon}/>;
    
    isBrick = () => brickTypes.includes(this.type);
}

const brickLovs = [
    /**
     * Bricks
     */
    new LovType('Site Brick', 'BRICK',
        'globe', Sorts.sortAlphabet, null),
    
    new LovType('Site Mini Brick', 'MINI',
        'globe', Sorts.sortAlphabet, null),
    
    new LovType('Site Micro Brick', 'MICRO',
        'globe', Sorts.sortAlphabet, null),
    
    new LovType('Site Farmmo Brick', 'FARMMO',
        'globe', Sorts.sortAlphabet, null)
];

const brickTypes = brickLovs.map(lov => lov.type);

const LovTypes = [
    new LovType('Person Working Specialty', 'LINK_SPC',
        'briefcase', Sorts.sortAlphabet, 'Indicates which medical area the HCP is working with at a given HCO'),
    
    new LovType('Person Type', 'PERS_TYPE',
        'user-md', Sorts.sortByCount, 'Indicates what type of HCP a person is'),
    
    new LovType('Person Education Specialty', 'EDUCATION',
        'graduation-cap', Sorts.sortAlphabet, 'Indicates what official specialty the doctor has'),
    
    new LovType('Person Position', 'POSITION',
        'stethoscope', Sorts.sortByCount, 'Indicates what type of role a HCP has on a given HCO'),
    
    new LovType('Site Specialty', 'SITE_SPC',
        'ambulance', Sorts.sortAlphabet, 'Indicates what kind of medical areas that are in focus on a HCO'),
    
    new LovType('Site Type', 'SITE_TYPE',
        'hospital', Sorts.sortByCount, 'Indicates what type of HCO a site is'),
    
    new LovType('Person Mail Code', 'PERS_KOL',
        'filter', Sorts.sortAlphabet, null),
    
    new LovType('Site Mail Code', 'SITE_KOL',
        'filter', Sorts.sortAlphabet, null),
    
    new LovType('Link Filter Code', 'SP_FILTER',
        'filter', Sorts.sortAlphabet, null),
    
    new LovType('Geo Location', 'COUNTY',
        'globe', Sorts.sortAlphabet, null, 'GeoTree'),
    
    ...brickLovs
];

export default LovTypes

export const LovOptions = LovTypes.map(lov =>
    <AutoComplete.Option key={lov.type}>{lov.getImage()} {lov.title}
    </AutoComplete.Option>);

export const LovOptionsNoBrick = LovOptions.filter(lo => !brickLovs.some(brick => brick.type === lo.key));

export const byType = type => LovTypes.find(l => l.type === type);

