// import {confirmPasswordValidator} from "./formValidators";


export const lengthRule = (min = 0, max = 100, required = true) => ({
    min, max, required,
    // TODO: Provide better message
    message: `You have to provide between ${min} and ${max} characters.`,
});

export const newPasswordRule = () => ({
    rules: [
        {required: true, message: 'Please enter a new password!', whitespace: true},
        {min: 8, message: 'The password has to be at least 8 characters long'},
        {max: 200, message: 'The password should not be longer than 200 characters'},
        {pattern: /\d/, message: 'The password should contain at least one number'},
        {pattern: /^\S*$/, message: 'The password should not contain any whitespace'}
    ]
});

export const confirmPasswordsRule = (getFieldValue) => ({
    rules: [
        {required: true, message: 'Please confirm your new password!', whitespace: true},
        // {validator: confirmPasswordValidator({getFieldValue})}, // TODO: Need to run this validation also when newPasswordRule has changed.
    ]
});
