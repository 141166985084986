import * as React from "react";
import PropTypes from "prop-types"
import Countries, {CountryOptions} from "../../constants/Countries";
import {Form, Select} from "antd"
import BrickAdd from "./BrickAdd";

const FormItem = Form.Item;
const SelectOption = Select.Option;

class BrickSettings extends React.Component {
    
    state = {
        brickCountry: null,
        brickType: null
    };
    
    addBrick = () => {
        const {brickCountry, brickType} = this.state;
        this.props.addBrick(brickCountry, brickType);
        this.setState({brickCountry: null, brickType: null});
    };
    
    changeBrickCountry = country => this.setState({brickCountry: country});
    changeBrickType = type => this.setState({brickType: type});
    
    renderFields = (countryCode) => {
        const {countryBricks} = this.props;
        const bricks = countryBricks[countryCode];
        return bricks.map((brick, index) => this.renderField(countryCode, brick, 'db' + index));
    };
    
    renderField = (countryCode, brick, key) => {
        const {formItemLayout, getFieldDecorator} = this.props;
        const country = Countries[countryCode];
        return <FormItem key={countryCode + brick + key.toString()}
                         label={<span>{country.getFlag()} {country.name}</span>} {...formItemLayout}>
            {getFieldDecorator(`brick-${country.key}-${key ? key.toString() : ''}`, {
                initialValue: brick
            })(
                <Select style={{width: '50%'}} allowClear={true}>
                    <SelectOption value="BRICK">Brick</SelectOption>
                    <SelectOption value="FARMMO">FARMMO</SelectOption>
                    <SelectOption value="MINI">Mini</SelectOption>
                    <SelectOption value="MICRO">Micro</SelectOption>
                </Select>
            )}
        </FormItem>
    };
    
    render() {
        const {countryBricks, formItemLayout, getFieldDecorator, countries, addedBricks} = this.props;
        const {brickCountry, brickType} = this.state;
        const countryOptions = CountryOptions.filter(co => countries.includes(co.key));
        return <div>
            {
                Object.keys(countryBricks).map(this.renderFields).reduce((acc, list) => acc.concat(list), [])
            }
            {
                addedBricks.map((x, index) => this.renderField(x.country, x.type, index))
            }
            <div>
                <BrickAdd countryOptions={countryOptions} addBrick={this.addBrick} getFieldDecorator={getFieldDecorator}
                          formItemLayout={formItemLayout} changeBrickCountry={this.changeBrickCountry}
                          brickType={brickType}
                          brickCountry={brickCountry}
                          changeBrickType={this.changeBrickType} disabled={!brickCountry || !brickType}/>
            </div>
        </div>
    }
}

BrickSettings.propTypes = {
    countryBricks: PropTypes.object.isRequired,
    countries: PropTypes.array.isRequired,
    addBrick: PropTypes.func.isRequired,
    addedBricks: PropTypes.array.isRequired,
    formItemLayout: PropTypes.object.isRequired,
    getFieldDecorator: PropTypes.func.isRequired
};

export default BrickSettings