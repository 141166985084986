import React from 'react';
import {Form, Radio} from 'antd';
import {formItemLayout} from "../UserEditor";

export default ({
                    initialValue,
                    form: {getFieldDecorator},
                    disabled = false,
}) => (
    <Form.Item label="Role" {...formItemLayout} >
        {getFieldDecorator('role', {
            rules: [
                {required: true, message: 'Please select a role!'}
            ],
            initialValue,
        })(
            <Radio.Group disabled={disabled}>
                <Radio value="User">User</Radio>
                <Radio value="Admin">Admin</Radio>
            </Radio.Group>
        )}
    </Form.Item>
);
