import React from "react"
import {Layout} from "antd"

const {Footer} = Layout;

export const WithFooter = ({ children, style }) => (
  <div style={{
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 50px)',
  }}>
    <div style={{ flex: 1 }}>
      {children}
    </div>
    <Footer className="footer">
      <p>
        <a href="mailto:support@apurebase.com">Contact & Technical Support</a> (<a href="tel:+4531333391">+45 31 33 33
          91</a>) • <a href="http://apurebase.com/contact/support/">Find your
          local contact person here</a> • <a href="mailto:sales@apurebase.com">Sales</a>
      </p>
      <p><a href="http://www.apurebase.com">PureMailing ©2018 created by aPureBase</a></p>
    </Footer>
  </div>
);
