import React from 'react';
import {Form, Switch} from 'antd';
import {formItemLayout} from "../UserEditor";

export default ({initialValue, disabled, form: {getFieldDecorator}}) => (
    <Form.Item label="Status" {...formItemLayout} >
        {getFieldDecorator('locked', {
            valuePropName: 'checked',
            rules: [
                {required: true, message: 'Please select a role!'}
            ],
            initialValue,
        })(
            <Switch disabled={disabled} checkedChildren="Locked"
                    unCheckedChildren="Unlocked"/>
        )}
    </Form.Item>
);
