import React from "react"
import {Menu} from "antd";
import LovTypes from "../../constants/LovTypes";
import {connect} from "react-redux";
import * as WizardActions from "../../reducers/wizard";
import {bindActionCreators} from "redux";
import PropTypes from "prop-types";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {compose} from 'recompose';

const LovMenu = ({
    selectType,
    selectedType,
    availableLovs,
    collapsed,
}) => {
    console.log('LovMenu');
    const makeMenuItem = (lovType, collapsed, entity) => {
        const {title, icon, tooltip} = lovType;
        const fixedTitle = entity ? title.substring(entity.length) : title;
        if (collapsed) return <span>
            <div><FontAwesomeIcon icon={icon}/></div>
            <div>{fixedTitle}</div>
            <div>{tooltip}</div>
        </span>;
        else return <div>
            <FontAwesomeIcon icon={icon}/> <span>{fixedTitle}</span>
        </div>
    };

    const make = (lovType, collapsed, entity) => <Menu.Item key={lovType.type}>
        {makeMenuItem(lovType, collapsed, entity)}
    </Menu.Item>;


    const types = LovTypes
        .filter(lovType => availableLovs.some(l => l.type === lovType.type))
        .sort((a, b) => b.title.indexOf('Person') - a.title.indexOf('Person'));

    const persSubTypes = types
        .filter(t => t.title.indexOf('Person') === 0)
        .map(lovType => make(lovType, collapsed, 'Person'));
    const siteSubTypes = types
        .filter(t => t.title.indexOf('Site') === 0)
        .map(lovType => make(lovType, collapsed, 'Site'));

    return (
        <Menu
            selectedKeys={[selectedType]}
            mode="inline"
            theme="dark"
            inlineCollapsed={collapsed}
            onClick={item => selectType(item.key)}
            defaultOpenKeys={['person', 'site']}
        >
            {
                collapsed
                    ? types.map(lovType => make(lovType, collapsed))
                    : [

                        <Menu.SubMenu key="person" title="Person">
                            {persSubTypes}
                        </Menu.SubMenu>,
                        <Menu.SubMenu key="site" title="Site">
                            {siteSubTypes}
                        </Menu.SubMenu>
                    ]
            }
        </Menu>
    );
};

LovMenu.propTypes = {
    availableLovs: PropTypes.array.isRequired,
    selectedType: PropTypes.string.isRequired,
    collapsed: PropTypes.bool.isRequired,
    selectType: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    selectedType: state.wizard.selectedType,
    availableLovs: state.auth.availableLovs,
});

const mapDispatchToProps = dispatch => ({
    selectType: bindActionCreators(WizardActions, dispatch).selectType,
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps)
)(LovMenu);
