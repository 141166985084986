import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Button, Form, Input, Modal} from 'antd';
import {lengthRule} from "../../util/formRules";
import {setStateOnChange} from "../../util/Utility";

export const saveTypes = {
    CANCEL: -1,
    SAVE: 1,
    SAVE_AS: 2,
};

const nameTemplate = ({title}) => `${title} (Copy)`;

class SaveAsModal extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            id: null,
            selectionName: null,
            selectedOnce: false,
        };
        this.footer = this.makeFooter();
    }

    componentDidUpdate(prevProps) {
        const {selection} = this.props;
        // This is needed if they just accept the copy naming template and
        // never touch the input field.
        if (this.state.id !== selection.id) {
            this.setState({id: selection.id, selectionName: nameTemplate(selection)});
        }
    }

    makeFooter = () => (
        <Button.Group>
            <Button type="ghost" onClick={this.onCancel}>Cancel</Button>
            <Button type="primary" onClick={this.onSave} loading={this.props.loading}>Save As</Button>
        </Button.Group>
    );

    transformSelection = () => ({
        ...this.props.selection,
        id: null, title: this.state.selectionName,
    });

    onCancel = () => this.props.handleSaveSelection(saveTypes.CANCEL);
    onSave = () => this.props.handleSaveSelection(saveTypes.SAVE, this.transformSelection());

    handleFocus = e => {
        if (this.state.selectedOnce) return;
        e.target.select();
        this.setState({selectedOnce: true});
    };

    render() {
        const {visible, selection, form: {getFieldDecorator}} = this.props;
        console.log('SaveAsModal:render');
        return (
            <Modal
                title="Save As"
                footer={this.footer}
                closable={true}
                width={800}
                visible={visible}
                onCancel={this.onCancel}
                afterClose={this.reset}
            >
                <Form layout="horizontal">
                    <Form.Item>
                        {
                            getFieldDecorator('selectionName', {
                                rules: [lengthRule(0, 100)],
                                initialValue: nameTemplate(selection),
                            })(<Input
                                key='selectionName'
                                onChange={setStateOnChange(this)}
                                autoFocus
                                onFocus={this.handleFocus}
                                onPressEnter={this.onSave}
                            />)
                        }
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

SaveAsModal.propTypes = {
    selection: PropTypes.shape({
        title: PropTypes.string,
        id: PropTypes.number,
    }),
    visible: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    handleSaveSelection: PropTypes.func.isRequired,
    form: PropTypes.shape({
        getFieldDecorator: PropTypes.func.isRequired,
        resetFields: PropTypes.func.isRequired,
        validateFields: PropTypes.func.isRequired,
    }).isRequired,
};

export default Form.create()(SaveAsModal);
