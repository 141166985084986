import React, {Component} from "react";
import PropTypes from "prop-types"
import {Button, Col, Form, Input, Modal, Row} from "antd"

const FormItem = Form.Item;

class LocationVerifier extends Component {
    
    static propTypes = {
        visible: PropTypes.bool.isRequired,
        verifying: PropTypes.bool.isRequired,
        verifyLocation: PropTypes.func.isRequired,
        handleVerify: PropTypes.func.isRequired
    };
    
    onOk = e => {
        this.props.handleVerify(e, this.props.form)
    };
    
    render() {
        const {visible, verifying, form} = this.props;
        const {getFieldDecorator} = form;
        const footer = <span><Button>Cancel</Button> <Button onClick={this.onOk} htmlType="submit" loading={verifying}
                                                             type="primary">Verify</Button></span>;
        return <Modal visible={visible} title="Two-Factor Authentication" footer={footer} closable={true}
                      maskCloseable={false}>
            <Form onSubmit={this.onOk}>
                <p>We noticed that you are logging on from this location for the first time.</p>
                <p>Therefore, we sent you an email with a code to verify your identity.</p>
                <p>We use this information to secure the application and prevent misuse.</p>
                <p>By continuing you confirm that we may register your IP address.</p>
                <Row gutter={20} type="flex" justify="center">
                    <Col span={6}>
                        <FormItem>
                            {getFieldDecorator('code', {
                                rules: [{
                                    required: true,
                                    whitespace: true,
                                    message: 'Please enter the verification code'
                                }]
                            })(<Input placeholder="Enter code" disabled={verifying}/>)}
                        </FormItem>
                    </Col>
                </Row>
            </Form>
        </Modal>
    }
    
}

export default Form.create()(LocationVerifier)

/*
<Col offset={3} span={span}><Input/></Col>
                <Col span={span}><Input/></Col>
                <Col span={span}><Input/></Col>
    
                <Col offset={1} span={span}><Input/></Col>
                <Col span={span}><Input/></Col>
                <Col span={span}><Input/></Col>
 */
