import {endsWith, flow, toLower} from 'lodash/fp';
import {Avatar} from "antd";
import React from "react"

export const onlyUnique = (value, index, self) => self.indexOf(value) === index;

export const isSelectionEmpty = (selection, availableLovs) => availableLovs
    .map(lovType => lovType.type)
    .every(type => !selection[type] || selection[type].length === 0);

export const formItemLayout = {
    labelCol: {span: 8},
    wrapperCol: {span: 14},
};

export const checkBrowser = () => !!document.documentMode;

export const getFieldKey = field => field.entity + field.type + field.name;

/**
 *
 * @param component - React component
 * @returns {function(*): *}
 * @example <Input id="modal-selection-name" onChange={setStateOnChange(this)}/>
 */
export const setStateOnChange = component => e => component.setState({[e.target.id || e.target.key]: e.target.value});

/**
 *
 * @param inputValue
 * @param option
 * @returns {boolean}
 * @example <AutoComplete
 *              dataSource={optionsList}
 *              filterOption={autocompleteFilter}
 *          >
 */
export const autocompleteFilter = (inputValue, option) => !inputValue || (
    option
    && option.props.children.indexOf
    && option.props.children.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
);

export const makeNewsAvatar = (type) => {
    const iconType = type === 'warning' ? 'exclamation' : 'info';
    const colors = type === 'warning' ? {backgroundColor: '#faad14'} : {backgroundColor: '#1890ff'};
    return <Avatar icon={iconType} style={colors}/>
};

/**
 *
 * @para {text} email address
 * @return {boolean}
 */
export const isApureBaseEmail = flow(toLower, endsWith("@apurebase.com"));
