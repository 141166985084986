import React from 'react';
import {AutoComplete, Form, Input, Icon} from 'antd';
import {formItemLayout} from "../UserEditor";

export default ({initialValue, onChange, options, disabled, form: {getFieldDecorator}}) => (
    <Form.Item label="Account" {...formItemLayout} >
        {getFieldDecorator('accountId', {
            rules: [
                {
                    required: true,
                    type: "string",
                    message: 'Please select an Account!',
                    whitespace: true
                }
            ],
            initialValue,
        })(
            <AutoComplete className="certain-category-search"
                          dropdownClassName="certain-category-search-dropdown"
                          onChange={onChange}
                          filterOption={(inputValue, option) => {
                              if (!inputValue) return true;
                              if (option && option.props.children.indexOf) return option.props.children.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1;
                              else return false
                          }}
                          dataSource={options} allowClear={true} disabled={disabled}
            >
                <Input prefix={<Icon type="bank"/>}/>
            </AutoComplete>
        )}
    </Form.Item>
);
