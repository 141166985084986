import * as React from "react";
import PropTypes from "prop-types"
import {Table} from "antd"

class RegisteredIPTable extends React.Component {
    
    render() {
        const {ips, fetchingIps, getAvatar} = this.props;
        const ipColumns = [
            {
                title: 'User', key: 'user', dataIndex: 'userId',
                render: (userId, record, i) => getAvatar(userId)
            },
            {
                title: 'IP', key: 'ip', dataIndex: 'ips',
                render: (ips, record, i) => <span>{ips.join(", ")}</span>
            }
        ];
        
        return <Table title={currentPageData => <span>Registered IP Addresses</span>} columns={ipColumns}
                      dataSource={ips} loading={fetchingIps} rowKey="userId"/>
    }
    
}

RegisteredIPTable.propTypes = {
    ips: PropTypes.array.isRequired,
    fetchingIps: PropTypes.bool.isRequired,
    getAvatar: PropTypes.func.isRequired
};


export default RegisteredIPTable