import React from "react";
import {Provider} from "react-redux";
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import AppLayout from "./AppLayout";
import Mailing from "./mailing/Mailing";
import Home from "./home/Home";
import Archive from "./archive/Archive";
import LoginForm from "./authentication/LoginForm";
import LogoutPage from "./authentication/LogoutPage";
import Settings from "./settings/Settings";
import * as AuthActions from "./reducers/auth"
import ListFormat from "./template/ListFormat";
import NotFound from "./default/NotFound";
import LiteralSelection from "./literal/LiteralSelection";
import ResetPassword from "./authentication/ResetPassword";

class App extends React.Component {

    componentDidMount() {
        this.props.store.dispatch(AuthActions.init());
    }

    PrivateRoute = ({component: Component, ...rest}) => <Route {...rest} render={props =>
        this.props.store.getState().auth.loggedIn
            ? <Component {...props} />
            : <Redirect to={{pathname: '/login', from: props.location}}/>
    }/>;

    render() {
        const {PrivateRoute} = this;
        const {store} = this.props;
        return <Provider store={store}>
            <BrowserRouter>
                <AppLayout>
                    <Switch>

                        <PrivateRoute component={Home} path="/" exact/>

                        <PrivateRoute component={Archive} path="/archive"/>

                        <PrivateRoute component={Mailing} path="/selection/:id?"/>

                        <PrivateRoute component={ListFormat} path="/templates/:id?"/>

                        <PrivateRoute component={LiteralSelection} path="/literal"/>

                        <PrivateRoute component={LogoutPage} path="/logout"/>

                        <PrivateRoute component={Settings} path="/settings"/>

                        <Route component={ResetPassword} path="/login/help"/>

                        <Route path="/login" render={props => {
                            const loggedIn = store.getState().auth.loggedIn;
                            const {pathname} = props.location.from || {from: {pathname: '/'}};
                            let finalPath = pathname;
                            if (pathname === "/login" || !pathname) finalPath = "/";
                            return loggedIn ? <Redirect to={finalPath}/> : <LoginForm/>
                        }}/>

                        <Route component={NotFound}/>

                    </Switch>
                </AppLayout>

            </BrowserRouter>
        </Provider>
    };
}

export default App;
