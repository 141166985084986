import {flatten, flow, omit, pick, values} from 'lodash/fp';
import {logout} from "./auth";
import {getCount as startCounting, incrementActionCount, resetActionCount} from "./wizard"
import {isApureBaseEmail} from "../util/Utility";

export const evalError = (dispatch, action, error) => {
    console.error('evalError', error);
    const {type, message} = error.response.data.message ? error.response.data : error;
    const result = dispatch(action({message, type}));
    if (type === "TokenExpiredException") dispatch(logout());
    return result;
};

export const getConfig = (getState) => {
    const token = getState().auth.token;
    if (!token) console.error("Token is missing!");
    return {headers: {"Authorization": 'Bearer ' + token}}
};

export const getSelection = (getState, selection) => {
    const actualSelection = selection || getState().selection;
    const lovExclude = ["id", "country", "title", "type"];
    const lovs = flow(
        omit(lovExclude),
        values,
        flatten,
    )(actualSelection);
    return {
        ...pick(lovExclude)(actualSelection),
        lovs,
    };
};

export const autoCount = (dispatch, getState) => {
    const getCount = () => getState().wizard.changeCounter;
    const initialCount = getCount();
    dispatch(incrementActionCount());
    if (initialCount === 0) {
        waitForNoChange(getCount, () => {
            dispatch(resetActionCount());
            dispatch(startCounting())
        })
    }
};

const waitForNoChange = (getCount, resolve) => {
    const current = getCount();
    setTimeout(() => {
        const newCount = getCount();
        if (newCount === current) resolve();
        else waitForNoChange(getCount, resolve)
    }, 750)
};

export const downloadPath = path => {
    window.location.href = path;
    return path
};

export const compareLovs = (previous, next) => {
    for (let i = 0; i < next.length; i++) {
        if (previous[i] !== next[i]) {
            return false
        }
    }
    return true
};

export const mapFetchedUsers = user => ({
    ...user,
    hasApureBase: isApureBaseEmail(user.email),
});
